import { CreatePerson } from '@colensobbdo/shelter-management-frontend-integration';

export enum RegistrationPageStep {
  CreatePerson,
  OptInAllUpdates,
  OptInMarketing,
  CompleteRegistration,
  ActivationRequestConfirmation,
  ProfileCreated,
}

export type RegistrationUserDetails = CreatePerson.ValidateRequest;

export type RegistrationOptInMarketing = {
  optInMarketingMyHooman: boolean;
  optInMarketingMarsAndAffiliates: boolean;
};
export interface RegistrationState extends RegistrationUserDetails, RegistrationOptInMarketing {
  step: RegistrationPageStep;
  acceptTerms: boolean;
  optInAllUpdates: boolean;
}

export const SET_REGISTRATION_STEP = 'SET_REGISTRATION_STEP';
export const SET_REGISTRATION_USER = 'SET_REGISTRATION_USER';
export const SET_REGISTRATION_ACCEPT_TERMS = 'SET_REGISTRATION_ACCEPT_TERMS';
export const SET_REGISTRATION_OPT_IN_ALL_UPDATES = 'SET_REGISTRATION_OPT_IN_ALL_UPDATES';
export const SET_REGISTRATION_OPT_IN_MARKETING = 'SET_REGISTRATION_OPT_IN_MARKETING';
export const CLEAR_REGISTRATION = 'CLEAR_REGISTRATION';

interface SetRegistrationStepAction {
  type: typeof SET_REGISTRATION_STEP;
  payload: RegistrationPageStep;
}

interface SetRegistrationUserAction {
  type: typeof SET_REGISTRATION_USER;
  payload: RegistrationUserDetails;
}

interface SetRegistrationAcceptTermsAction {
  type: typeof SET_REGISTRATION_ACCEPT_TERMS;
  payload: boolean;
}

interface SetRegistrationOptInAllUpdatesAction {
  type: typeof SET_REGISTRATION_OPT_IN_ALL_UPDATES;
  payload: boolean;
}

interface SetRegistrationOptInMarketingAction {
  type: typeof SET_REGISTRATION_OPT_IN_MARKETING;
  payload: RegistrationOptInMarketing;
}

interface ClearRegistrationAction {
  type: typeof CLEAR_REGISTRATION;
}

export type RegistrationAction =
  | SetRegistrationStepAction
  | SetRegistrationUserAction
  | SetRegistrationAcceptTermsAction
  | SetRegistrationOptInAllUpdatesAction
  | SetRegistrationOptInMarketingAction
  | ClearRegistrationAction;

import { AnimalSpecieCategoryId } from '@colensobbdo/shelter-management-frontend-integration';
import React from 'react';

const OnboardingIntroImage: React.FC<{ type?: AnimalSpecieCategoryId }> = () => {
  return (
    <svg width="303" height="291" viewBox="0 0 303 291" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_8_50)">
        <path
          d="M86.3516 189.44H155.412C157.617 189.44 159.404 187.653 159.404 185.448V131.656C159.404 129.451 161.191 127.664 163.396 127.664H230.414"
          stroke="#4353CD"
          strokeWidth="1.99585"
          strokeDasharray="2 3.99"
        />
        <rect
          x="0.540783"
          y="0.453045"
          width="92.8035"
          height="125.97"
          rx="15.4679"
          transform="matrix(0.996105 -0.0881728 0.087708 0.996146 24.8296 110.569)"
          fill="white"
          stroke="#9EA1B3"
          strokeWidth="0.997926"
        />
        <rect
          x="0.550883"
          y="0.440641"
          width="92.8046"
          height="124.722"
          rx="15.4679"
          transform="matrix(0.993843 -0.110794 0.110213 0.993908 24.697 106.325)"
          fill="white"
          stroke="#9EA1B3"
          strokeWidth="0.997926"
        />
        <rect
          x="0.562578"
          y="0.425527"
          width="92.8063"
          height="121.054"
          rx="15.4679"
          transform="matrix(0.990468 -0.137745 0.137027 0.990567 24.6892 103.421)"
          fill="white"
          stroke="#9EA1B3"
          strokeWidth="0.997926"
        />
        <rect
          width="92.3153"
          height="93.2986"
          rx="14.9689"
          transform="matrix(0.990468 -0.137745 0.137027 0.990567 25.5781 103.98)"
          fill="#D9F9E4"
        />
        <mask
          id="mask0_8_50"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="-9"
          y="36"
          width="168"
          height="161"
        >
          <path
            d="M8.84026 61.5901C10.4163 55.7912 15.3053 51.4964 21.257 50.6825L125.389 36.4419C133.578 35.322 141.124 41.0554 142.242 49.2478L158.029 164.887C159.147 173.08 153.415 180.629 145.225 181.749L40.9429 196.01C35.0676 196.813 29.2726 194.071 26.1676 189.018L-6.46756 135.904C-8.63077 132.384 -9.2429 128.125 -8.15899 124.137L8.84026 61.5901Z"
            fill="#D9F9E4"
          />
        </mask>
        <g mask="url(#mask0_8_50)">
          <path
            d="M38.6769 124.141C38.6559 124.036 38.6348 123.93 38.5927 123.846C38.0032 121.8 36.9929 119.754 36.0458 117.876C35.5407 116.864 34.9514 115.83 34.2781 114.775C31.8791 111.042 28.029 107.772 23.4856 107.264C14.1675 106.228 7.41846 114.851 4.07654 122.485C1.42824 128.579 -1.76608 137.373 1.47507 143.806C3.36924 147.561 7.84991 149.334 11.8881 148.682C15.6319 148.071 18.7018 145.183 19.7944 141.577C20.3617 139.658 20.214 137.886 19.8768 135.946C19.3289 132.698 19.4119 129.091 20.2943 125.843C20.5885 124.768 21.7229 120.382 23.5953 121.753C24.3527 122.323 24.6475 123.483 25.0263 124.305C26.0997 126.499 26.2267 129.03 28.0992 130.739C29.7403 132.237 32.0962 132.786 34.2414 132.175C37.7747 131.185 39.5194 127.6 38.6769 124.141Z"
            fill="#FFD626"
          />
          <path
            d="M56.9108 124.129C56.9318 124.023 56.9528 123.918 56.9949 123.834C57.5832 121.788 58.5921 119.743 59.538 117.866C60.0425 116.854 60.6311 115.82 61.3039 114.766C63.7005 111.034 67.5486 107.766 72.0917 107.261C81.4091 106.231 88.1635 114.859 91.5102 122.495C94.1624 128.591 97.3622 137.386 94.125 143.818C92.2332 147.571 87.7536 149.341 83.715 148.686C79.9709 148.074 76.8991 145.183 75.8043 141.576C75.2358 139.657 75.3824 137.886 75.7184 135.945C76.2642 132.698 76.179 129.091 75.2945 125.843C74.9997 124.767 73.8626 120.38 71.991 121.75C71.234 122.32 70.9399 123.479 70.5615 124.302C69.4895 126.495 69.3641 129.026 67.4927 130.733C65.8525 132.23 63.497 132.778 61.3514 132.166C57.7964 131.173 56.0706 127.587 56.9108 124.129Z"
            fill="#FFD626"
          />
          <path
            d="M48.1335 236.793C34.1524 236.788 22.8168 225.415 22.8124 211.388L22.7891 136.842C22.7848 122.815 34.1133 111.449 48.0944 111.453C62.0755 111.458 73.4111 122.81 73.4155 136.858L73.4388 211.404C73.4431 225.431 62.1146 236.797 48.1335 236.793Z"
            fill="#FFD626"
          />
          <path
            d="M53.4942 134.716C53.4945 135.749 48.1098 138.362 48.1098 138.362C48.1098 138.362 42.7235 135.767 42.7232 134.712C42.7229 133.679 45.1419 132.837 48.1081 132.838C51.0744 132.839 53.4939 133.683 53.4942 134.716Z"
            fill="#FFD626"
          />
          <path
            d="M48.1146 138.697C48.0725 138.697 48.0094 138.676 47.9673 138.654C46.2841 137.853 42.3916 135.827 42.3913 134.71C42.3908 133.276 45.3358 132.497 48.1126 132.498C50.8895 132.499 53.8349 133.28 53.8353 134.714C53.8357 135.831 49.9445 137.833 48.2618 138.655C48.1987 138.676 48.1566 138.697 48.1146 138.697ZM48.1128 133.173C44.9783 133.172 43.0643 134.078 43.0645 134.71C43.1067 135.174 45.4002 136.651 48.1143 137.98C50.8277 136.652 53.1202 135.177 53.1622 134.713C53.162 134.081 51.2263 133.174 48.1128 133.173Z"
            fill="#FFB406"
          />
          <path
            d="M108.938 225.658C94.7466 225.655 83.2325 214.121 83.2301 199.905L83.2191 133.295C83.2168 119.079 94.7271 107.549 108.919 107.551C123.11 107.554 134.624 119.088 134.626 133.303L134.637 199.914C134.64 214.151 123.13 225.66 108.938 225.658Z"
            fill="#EBA6B0"
          />
          <path
            d="M112.774 130.558C112.775 131.313 108.901 133.178 108.901 133.178C108.901 133.178 105.028 131.312 105.028 130.557C105.027 129.802 106.765 129.216 108.901 129.216C111.057 129.195 112.774 129.804 112.774 130.558Z"
            fill="#EBA6B0"
          />
          <path
            d="M108.932 133.529L108.785 133.466C107.278 132.733 104.723 131.391 104.723 130.552C104.723 129.441 106.837 128.875 108.931 128.876C111.025 128.876 113.14 129.463 113.14 130.553C113.14 131.392 110.607 132.733 109.078 133.467L108.932 133.529ZM108.931 129.526C106.649 129.525 105.393 130.196 105.393 130.531C105.498 130.888 107.278 131.957 108.932 132.775C110.565 131.978 112.323 130.91 112.47 130.532C112.428 130.197 111.171 129.526 108.931 129.526Z"
            fill="#CE8894"
          />
          <path
            d="M94.4768 117.284C94.4768 117.284 92.5275 98.3492 87.7353 98.3484C82.1479 98.3475 83.4086 129.527 83.2219 139.571"
            fill="#EBA6B0"
          />
          <path
            d="M123.335 117.282C123.335 117.282 125.278 98.348 130.07 98.3488C135.658 98.3497 134.428 129.508 134.618 139.552"
            fill="#EBA6B0"
          />
          <path
            d="M78.058 133.631C77.9323 133.631 77.8067 133.547 77.7439 133.422C77.681 133.254 77.7647 133.045 77.9322 132.982C86.6422 129.446 99.5203 132.818 100.065 132.965C100.253 133.007 100.358 133.195 100.295 133.384C100.253 133.572 100.065 133.677 99.8763 133.614C99.7507 133.572 86.6633 130.137 78.1836 133.589C78.1417 133.631 78.0999 133.631 78.058 133.631Z"
            fill="#CE8894"
          />
          <path
            d="M77.5377 139.696C77.4121 139.696 77.3073 139.633 77.2445 139.508C77.1607 139.34 77.2235 139.131 77.391 139.047C82.0599 136.597 87.9855 136.179 92.1105 136.243C96.5914 136.306 100.005 136.977 100.046 136.977C100.235 137.019 100.34 137.187 100.319 137.375C100.277 137.564 100.109 137.668 99.9209 137.647C99.7952 137.626 86.415 135.09 77.7052 139.654C77.6424 139.675 77.5796 139.696 77.5377 139.696Z"
            fill="#CE8894"
          />
          <path
            d="M139.77 133.635C139.728 133.635 139.686 133.635 139.645 133.614C131.143 130.158 118.077 133.589 117.952 133.631C117.763 133.673 117.596 133.568 117.533 133.401C117.491 133.213 117.596 133.045 117.763 132.982C118.308 132.836 131.185 129.447 139.896 133.007C140.063 133.07 140.147 133.279 140.084 133.447C140.021 133.572 139.896 133.635 139.77 133.635Z"
            fill="#CE8894"
          />
          <path
            d="M140.293 139.7C140.23 139.7 140.188 139.679 140.146 139.658C131.435 135.09 118.055 137.623 117.93 137.644C117.741 137.685 117.574 137.56 117.532 137.371C117.49 137.183 117.615 137.015 117.804 136.973C117.846 136.973 121.259 136.304 125.74 136.242C129.885 136.179 135.79 136.599 140.46 139.051C140.627 139.134 140.69 139.344 140.607 139.512C140.544 139.616 140.418 139.7 140.293 139.7Z"
            fill="#CE8894"
          />
        </g>
        <path
          d="M168.59 89.3648C168.021 88.7451 167.346 88.2535 166.603 87.9182C165.86 87.5828 165.063 87.4102 164.258 87.4102C163.454 87.4102 162.657 87.5828 161.914 87.9182C161.171 88.2535 160.496 88.7451 159.927 89.3648L158.747 90.6502L157.566 89.3648C156.418 88.1137 154.86 87.4108 153.235 87.4108C151.61 87.4108 150.052 88.1137 148.904 89.3648C147.755 90.6159 147.109 92.3127 147.109 94.082C147.109 95.8514 147.755 97.5482 148.904 98.7993L150.084 100.085L158.747 109.519L167.41 100.085L168.59 98.7993C169.159 98.1799 169.61 97.4445 169.918 96.6351C170.226 95.8257 170.385 94.9582 170.385 94.082C170.385 93.2059 170.226 92.3383 169.918 91.5289C169.61 90.7195 169.159 89.9841 168.59 89.3648Z"
          fill="#FF9EB0"
          stroke="#FF9EB0"
          strokeWidth="2.631"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          opacity="0.3"
          d="M170.101 57.3695C169.704 56.8791 169.222 56.4805 168.682 56.1966C168.143 55.9126 167.557 55.7489 166.957 55.7146C166.358 55.6803 165.757 55.7763 165.189 55.9969C164.621 56.2176 164.097 56.5586 163.646 57.0006L162.712 57.9173L161.888 56.9001C161.085 55.91 159.954 55.3149 158.744 55.2457C157.533 55.1765 156.342 55.6389 155.433 56.5312C154.523 57.4235 153.97 58.6725 153.894 60.0035C153.819 61.3346 154.227 62.6385 155.029 63.6286L155.854 64.6459L161.905 72.1122L168.763 65.3837L169.697 64.4669C170.148 64.0252 170.516 63.4912 170.78 62.8954C171.044 62.2996 171.199 61.6537 171.236 60.9946C171.274 60.3355 171.193 59.6761 170.998 59.0541C170.803 58.4321 170.498 57.8596 170.101 57.3695Z"
          fill="#FF9EB0"
        />
        <path
          opacity="0.5"
          d="M153.418 73.5996C153.183 73.3093 152.898 73.0733 152.578 72.9052C152.259 72.7371 151.912 72.6401 151.557 72.6198C151.202 72.5996 150.847 72.6564 150.51 72.787C150.174 72.9176 149.864 73.1195 149.597 73.3812L149.044 73.924L148.556 73.3217C148.081 72.7355 147.411 72.3832 146.694 72.3422C145.978 72.3013 145.273 72.575 144.734 73.1033C144.196 73.6316 143.868 74.3711 143.823 75.1591C143.778 75.9471 144.02 76.7192 144.495 77.3054L144.983 77.9076L148.566 82.3281L152.626 78.3444L153.18 77.8017C153.446 77.5402 153.664 77.224 153.82 76.8712C153.977 76.5185 154.069 76.1361 154.091 75.7459C154.113 75.3557 154.065 74.9653 153.95 74.597C153.834 74.2287 153.654 73.8898 153.418 73.5996Z"
          fill="#FF9EB0"
        />
        <rect
          width="123.84"
          height="125.172"
          rx="61.9201"
          transform="matrix(0.999997 -0.0025109 0.00249756 0.999997 178.848 102.895)"
          fill="white"
        />
        <mask
          id="mask1_8_50"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="178"
          y="71"
          width="125"
          height="157"
        >
          <rect
            width="123.84"
            height="156.222"
            rx="61.9201"
            transform="matrix(0.999997 -0.0025109 0.00249756 0.999997 178.77 71.8398)"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_8_50)">
          <path
            d="M205.735 114.631C209.009 114.631 211.662 111.971 211.662 108.689C211.662 105.407 209.009 102.746 205.735 102.746C202.462 102.746 199.809 105.407 199.809 108.689C199.809 111.971 202.462 114.631 205.735 114.631Z"
            fill="#52C3C4"
          />
          <path
            d="M276.657 114.631C279.93 114.631 282.584 111.971 282.584 108.689C282.584 105.407 279.93 102.746 276.657 102.746C273.384 102.746 270.73 105.407 270.73 108.689C270.73 111.971 273.384 114.631 276.657 114.631Z"
            fill="#52C3C4"
          />
          <path
            d="M276.656 105.504C278.414 105.504 279.832 106.926 279.832 108.688C279.832 110.451 278.414 111.873 276.656 111.873"
            fill="#52C3C4"
          />
          <path
            d="M276.657 112.357C276.402 112.357 276.203 112.158 276.203 111.902C276.203 111.646 276.402 111.447 276.657 111.447C278.16 111.447 279.379 110.225 279.379 108.718C279.379 107.211 278.16 105.988 276.657 105.988C276.402 105.988 276.203 105.789 276.203 105.533C276.203 105.277 276.402 105.078 276.657 105.078C278.67 105.078 280.315 106.727 280.315 108.746C280.287 110.708 278.67 112.357 276.657 112.357Z"
            fill="#1A9597"
          />
          <path
            d="M242.54 73.3461C242.37 73.0618 242.143 72.749 241.888 72.4078C240.272 70.3891 237.748 68.5693 234.345 66.9486C233.437 66.5221 232.36 66.9202 231.906 67.8301C231.481 68.7399 231.878 69.8488 232.785 70.2753C233.494 70.6165 234.118 70.9293 234.685 71.2705C233.04 70.6734 231.055 70.2185 228.645 70.1616C227.652 70.1332 226.802 70.9293 226.773 71.9244C226.745 72.948 227.539 73.7726 228.531 73.801C230.12 73.8579 231.481 74.0854 232.615 74.4266C217.33 78.3219 206.016 92.2257 206.016 108.774V233.197H276.683V108.745C276.654 89.6099 261.483 74.0001 242.54 73.3461Z"
            fill="#52C3C4"
          />
          <path
            d="M232.704 102.746C232.704 105.049 230.833 106.926 228.536 106.926C226.239 106.926 224.367 105.049 224.367 102.746"
            fill="#52C3C4"
          />
          <path
            d="M228.532 107.41C225.98 107.41 223.91 105.334 223.91 102.775C223.91 102.519 224.109 102.32 224.364 102.32C224.619 102.32 224.818 102.519 224.818 102.775C224.818 104.822 226.491 106.5 228.532 106.5C230.574 106.5 232.247 104.822 232.247 102.775C232.247 102.519 232.446 102.32 232.701 102.32C232.956 102.32 233.155 102.519 233.155 102.775C233.155 105.306 231.085 107.41 228.532 107.41Z"
            fill="#1A9597"
          />
          <path
            d="M258.282 102.746C258.282 105.049 256.411 106.926 254.114 106.926C251.817 106.926 249.945 105.049 249.945 102.746"
            fill="#52C3C4"
          />
          <path
            d="M254.114 107.41C251.562 107.41 249.492 105.334 249.492 102.775C249.492 102.519 249.691 102.32 249.946 102.32C250.201 102.32 250.4 102.519 250.4 102.775C250.4 104.822 252.073 106.5 254.114 106.5C256.156 106.5 257.829 104.822 257.829 102.775C257.829 102.519 258.028 102.32 258.283 102.32C258.538 102.32 258.737 102.519 258.737 102.775C258.737 105.306 256.667 107.41 254.114 107.41Z"
            fill="#1A9597"
          />
          <path
            d="M205.961 111.896C204.203 111.896 202.785 110.475 202.785 108.712C202.785 106.949 204.203 105.527 205.961 105.527"
            fill="#52C3C4"
          />
          <path
            d="M205.963 112.355C203.949 112.355 202.305 110.706 202.305 108.687C202.305 106.669 203.949 105.02 205.963 105.02C206.218 105.02 206.417 105.219 206.417 105.474C206.417 105.73 206.218 105.929 205.963 105.929C204.46 105.929 203.24 107.152 203.24 108.659C203.24 110.166 204.46 111.389 205.963 111.389C206.218 111.389 206.417 111.588 206.417 111.843C206.417 112.099 206.218 112.355 205.963 112.355Z"
            fill="#1A9597"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8_50">
          <rect width="303" height="291" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OnboardingIntroImage;

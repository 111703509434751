import {
  CLEAR_REGISTRATION,
  RegistrationAction,
  RegistrationOptInMarketing,
  RegistrationPageStep,
  RegistrationUserDetails,
  SET_REGISTRATION_ACCEPT_TERMS,
  SET_REGISTRATION_OPT_IN_ALL_UPDATES,
  SET_REGISTRATION_OPT_IN_MARKETING,
  SET_REGISTRATION_STEP,
  SET_REGISTRATION_USER,
} from './types';

export function setRegistrationStep(payload: RegistrationPageStep): RegistrationAction {
  return {
    type: SET_REGISTRATION_STEP,
    payload,
  };
}

export function setRegistrationUser(payload: RegistrationUserDetails): RegistrationAction {
  return {
    type: SET_REGISTRATION_USER,
    payload,
  };
}

export function setRegistrationAcceptTerms(payload: boolean): RegistrationAction {
  return {
    type: SET_REGISTRATION_ACCEPT_TERMS,
    payload,
  };
}

export function setRegistrationOptInAllUpdatesAction(payload: boolean): RegistrationAction {
  return {
    type: SET_REGISTRATION_OPT_IN_ALL_UPDATES,
    payload,
  };
}

export function setRegistrationOptInMarketingAction(payload: RegistrationOptInMarketing): RegistrationAction {
  return {
    type: SET_REGISTRATION_OPT_IN_MARKETING,
    payload,
  };
}

export function clearRegistration(): RegistrationAction {
  return {
    type: CLEAR_REGISTRATION,
  };
}

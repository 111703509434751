import { AUTH_DATA_CLEAR, AUTH_DATA_UPDATE, AuthActionTypes, AuthDataUpdatePayload } from './types';

export function authDataUpdate(data: Partial<AuthDataUpdatePayload>): AuthActionTypes {
  return {
    type: AUTH_DATA_UPDATE,
    payload: data,
  };
}

export function authDataClear(): AuthActionTypes {
  return {
    type: AUTH_DATA_CLEAR,
  };
}

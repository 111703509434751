import {
  ISuccessfulApiResponse,
  PersonFavouriteAnimal,
  makeLoggers,
} from '@colensobbdo/shelter-management-frontend-integration';
import AwaitLock from 'await-lock';
import _concat from 'lodash/concat';
import _debounce from 'lodash/debounce';
import _difference from 'lodash/difference';
import _intersection from 'lodash/intersection';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _uniq from 'lodash/uniq';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { isAuthenticated } from 'store/auth/selectors';
import { getPersonId } from 'store/person/selectors';

import { RootState } from '../store';
import { favoriteDataReplace } from './actions';
import { getFavoriteAnimalIds, getFavoriteAnimalIdsToDelete } from './selectors';

const { fork } = makeLoggers('state :: favorites :: thunk');

const lock = new AwaitLock();

async function syncFavorites(dispatch: Dispatch, getState: () => RootState) {
  const { logi, loge } = fork('syncFavorites');

  const state = getState();
  const PERSON_ID = getPersonId(state);
  const IS_LOGGED_IN = isAuthenticated(state);
  const FAVOURITE_ANIMAL_IDS = getFavoriteAnimalIds(state);

  if (!IS_LOGGED_IN || !PERSON_ID) {
    logi('skipping sync if not logged in');
    return;
  }

  // use mutex access to this function
  // so that we prevent concurrent sync issues
  // (this is not 100% safe as we should prevent this even from different clients...)

  logi('LOCK', lock);
  await lock.acquireAsync();
  logi('LOCKED');

  // sync policy:
  // local first (override remote changes)
  logi('sync with the remote');
  const response = await PersonFavouriteAnimal.get({ personId: PERSON_ID });

  if (!response.success) {
    loge('get api failed', response);

    //trigger update for dependant useEffects
    dispatch(favoriteDataReplace(FAVOURITE_ANIMAL_IDS));

    logi('UNLOCK');
    lock.release();

    return;
  }

  // get a list of all the favorites according to the server
  const REMOTE_IDS = (response as ISuccessfulApiResponse<PersonFavouriteAnimal.GetResponseData>).data;

  // get a list of all the favorites according to redux
  const LOCAL_IDS = getFavoriteAnimalIds(state);

  // get a list of favorites to remove that we need to sync with the server
  // (the local list is crossed with the remote list to get only ids that are on the server)
  const TO_DELETE_IDS = _intersection(getFavoriteAnimalIdsToDelete(state), REMOTE_IDS);

  // compute the new favorited animals to upload
  const TO_INSERT_IDS = _difference(LOCAL_IDS, REMOTE_IDS);

  // compute the new favorited animals to download
  const REMOTE_TO_INSERT_IDS = _difference(REMOTE_IDS, TO_DELETE_IDS);

  logi('data', { REMOTE_IDS, LOCAL_IDS, TO_DELETE_IDS, TO_INSERT_IDS, REMOTE_TO_INSERT_IDS });

  // if there are not changes to upload
  // consider the REMOTE_IDS list as an update
  if (_isEmpty(TO_DELETE_IDS) && _isEmpty(TO_INSERT_IDS)) {
    // if there are not favorites even on the server
    // abort the update
    if (_isEmpty(REMOTE_IDS)) {
      logi('nothing to sync');

      //trigger update for dependant useEffects
      dispatch(favoriteDataReplace(FAVOURITE_ANIMAL_IDS));

      logi('UNLOCK');
      lock.release();

      return;
    }

    logi('overriding local with remote list', REMOTE_IDS);

    dispatch(favoriteDataReplace(REMOTE_IDS));

    logi('UNLOCK');
    lock.release();

    return;
  }

  // push new animal ids
  await Promise.all(
    TO_INSERT_IDS.map(
      (animalId) =>
        new Promise(async (resolve, reject) => {
          PersonFavouriteAnimal.create({
            animalId,
            personId: PERSON_ID,
          })
            .then(resolve)
            .catch(reject);
        }),
    ),
  );
  logi('animals uploaded', TO_INSERT_IDS);

  // push animal ids to remove
  await Promise.all(
    TO_DELETE_IDS.map(
      (animalId) =>
        new Promise((resolve, reject) => {
          PersonFavouriteAnimal.deleteOne({
            animalId,
            personId: PERSON_ID,
          })
            .then(resolve)
            .catch(reject);
        }),
    ),
  );
  logi('animals removed', TO_DELETE_IDS);

  // update the local list with the final favorited animals by
  // considering the REMOTE_IDS - TO_DELETE_IDS + TO_INSERT_IDS
  // as the final list
  const FINAL_FAVORITE_IDS = _uniq(_concat(REMOTE_TO_INSERT_IDS, TO_INSERT_IDS));

  logi('final favorite ids list', FINAL_FAVORITE_IDS);

  dispatch(favoriteDataReplace(FINAL_FAVORITE_IDS));

  logi('UNLOCK');
  lock.release();
}

// debounce so that we can group multiple actions
const syncFavoritesDebounced = _debounce(syncFavorites, 1000, { trailing: true });

export const thunkSyncFavorites =
  (force = false): ThunkAction<void, RootState, unknown, Action<any>> =>
  async (dispatch, getState) => {
    const { logi } = fork('thunkSyncFavorites');

    logi('called', { force });

    if (force) {
      syncFavoritesDebounced.cancel();

      return syncFavorites(dispatch, getState);
    } else {
      syncFavoritesDebounced(dispatch, getState);
    }
  };

import { Dispatch } from 'redux';
import { preferenceDataClear } from 'store/preferences/actions';
import { clearRegistration } from 'store/registration/actions';
import { searchClearData } from 'store/search/actions';

import { authDataClear } from '../auth/actions';
import { favoriteDataClear } from '../favorites/actions';
import { personDataClear } from '../person/actions';
import { shelterDataClear } from '../shelter/actions';
import { usernameClearLastUsed } from '../username/actions';

export const clearDataBeforeLogin =
  () =>
  (dispatch: Dispatch): void => {
    dispatch<any>(authDataClear());
    dispatch<any>(personDataClear());
    dispatch<any>(shelterDataClear());
    dispatch<any>(clearRegistration());
  };

export const logout =
  () =>
  (dispatch: Dispatch): void => {
    dispatch<any>(clearDataBeforeLogin());
    dispatch<any>(favoriteDataClear());
    dispatch<any>(searchClearData());
  };

export const clearData =
  () =>
  (dispatch: Dispatch): void => {
    dispatch<any>(logout());
    dispatch<any>(preferenceDataClear());
    dispatch<any>(usernameClearLastUsed());
  };

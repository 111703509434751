// state

export type UsernameState = {
  lastUsername: string | undefined;
};

// action

export const USERNAME_STORE_LAST_USED = 'USERNAME_REMEMBER_LAST_USED';
export const USERNAME_CLEAR = 'USERNAME_CLEAR';

interface UsernameRememberLastUsedAction {
  type: typeof USERNAME_STORE_LAST_USED;
  payload: string;
}

interface UsernameRememberClearAction {
  type: typeof USERNAME_CLEAR;
}

export type UsernameActionTypes = UsernameRememberLastUsedAction | UsernameRememberClearAction;

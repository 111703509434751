import {
  SEARCH_CLEAR_DATA,
  SEARCH_CLEAR_SPECIES_DATA,
  SEARCH_STORE_ANIMAL_CATEGORY_ID,
  SEARCH_STORE_NEW_COORDINATE,
  SEARCH_STORE_PARAMETERS,
  SEARCH_STORE_REPLACE_ATTRIBUTES,
  SearchActionTypes,
  SearchState,
} from './types';

const initialState: SearchState = {
  attributeMap: null,
  animalSpeciesCategory: null,
  latitude: null,
  longitude: null,
};

export function searchReducer(state = initialState, action: SearchActionTypes): SearchState {
  switch (action.type) {
    case SEARCH_STORE_NEW_COORDINATE:
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };
    case SEARCH_STORE_REPLACE_ATTRIBUTES:
      return {
        ...state,
        attributeMap: {
          [action.payload.animalSpeciesCategory]: action.payload.attributes,
          ...state.attributeMap,
        },
      };
    case SEARCH_STORE_PARAMETERS:
      return {
        ...action.payload,
        attributeMap: {
          [action.payload.attributes.animalSpeciesCategory]: action.payload.attributes.attributes,
          ...state.attributeMap,
        },
      };
    case SEARCH_CLEAR_SPECIES_DATA: {
      if (state.attributeMap) delete state.attributeMap[action.payload];

      return {
        ...state,
      };
    }
    case SEARCH_CLEAR_DATA:
      return {
        ...initialState,
      };
    case SEARCH_STORE_ANIMAL_CATEGORY_ID:
      return {
        ...state,
        animalSpeciesCategory: action.payload,
      };
    default:
      return state;
  }
}

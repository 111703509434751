import packageJsonApi from '@colensobbdo/shelter-management-frontend-integration/package.json';
import packageJsonComponents from '@colensobbdo/shelter-management-frontend/package.json';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import CONFIG, { IS_DEV } from 'configs';
import { SENTRY_DSN } from 'configs/sentry';

import packageJsonWebapp from '../../../package.json';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  enabled: !IS_DEV,
  release: `${packageJsonWebapp.name}@${packageJsonWebapp.version}`,
  attachStacktrace: true,
  environment: CONFIG.environment,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // This configuration captures both error and performance data.
  // To reduce the volume of performance data captured, change tracesSampleRate to a value between 0 and 1.
  tracesSampleRate: 0.6,
});

Sentry.setExtra('apiclient', `${packageJsonApi.name}@${packageJsonApi.version}`);
Sentry.setExtra('components', `${packageJsonComponents.name}@${packageJsonComponents.version}`);

import _compact from 'lodash/compact';
import _concat from 'lodash/concat';
import _indexOf from 'lodash/indexOf';
import _map from 'lodash/map';

import { SHELTERS_CLEAR_DATA, SHELTERS_STORE_NEW_SHELTER, SheltersActionTypes, SheltersState } from './types';

const initialState: SheltersState = {
  list: [],
};

/**
 * @param state
 * @param action
 */
export function sheltersReducer(state = initialState, action: SheltersActionTypes): SheltersState {
  switch (action.type) {
    case SHELTERS_STORE_NEW_SHELTER: {
      const isSavedShelter = _indexOf(_map(state.list, 'id'), action.payload.id) !== -1;
      if (!isSavedShelter) {
        return {
          ...state,
          list: _compact(_concat(state.list, action.payload)),
        };
      }
      return state;
    }
    case SHELTERS_CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import {
  Anchor,
  Button,
  Container,
  Heading,
  Spacer,
  StackedPanel,
  Text,
} from '@colensobbdo/shelter-management-frontend';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Basketball from './assets/BasketBall.svg';
import Binoculars from './assets/Binoculars.svg';
import House from './assets/House.svg';
import Keyboard from './assets/Keyboard.svg';
import PetFood from './assets/PetFood.png';

export const WhatIsMyHoomanContent: React.FC = () => (
  <Container>
    <Spacer direction="vertical" size="large">
      <div className="max-w-520 pt-24 mx-auto">
        <Spacer direction="vertical" size="large">
          <Heading level="h3" tag="h1">
            What is MyHooman?
          </Heading>
          <Text>
            It’s a place for pet rescues and shelters across New Zealand to share their cats, dogs – and soon farm
            animals and other small four and two-leggers.
          </Text>
        </Spacer>
      </div>

      <div className="py-24">
        <div className="grid gap-48 grid-cols-2--md">
          <img className="w-full" src={Basketball} alt="What is MyHooman?" />
          <Text>
            On MyHooman, as a hooman looking to adopt a fur pal, you&apos;ll only need to fill in one adoption
            application, saving you lotsa time in the search for your perfect fluffy match.
          </Text>
        </div>
      </div>
    </Spacer>
  </Container>
);

export const AnimalMatchingContent: React.FC = () => (
  <Container>
    <Spacer direction="vertical" size="large">
      <div className="max-w-520 pt-24 mx-auto">
        <Spacer direction="vertical" size="large">
          <Heading level="h3" tag="h1">
            Animal Matching
          </Heading>

          <Text>
            {<strong>How does the match score work?</strong>}
            {<br />}
            MyHooman is here to help pets find their forever hooman. It&apos;s important to us, and the shelters and
            rescues that are part of our community, that each pet goes to the right fur-ever home, based on their needs.
          </Text>
        </Spacer>
      </div>
      <div className="py-24">
        <div className="grid gap-48 grid-cols-2--md">
          <img className="w-full" src={Keyboard} alt="Dog Matching" />
          <Spacer direction="vertical" size="large">
            <Text>
              The questions you answer as part of the matching process are linked to different data points we have on
              each pet. The score represents how closely your lifestyle matches their needs.
            </Text>
            <Text>
              The matching score is continually being tweaked and improved, but keep in mind it’s furry hard to find a
              perfect match. You should feel happy about applying to adopt pets when you have a score of upwards of 50%.
            </Text>
          </Spacer>
        </div>
      </div>
    </Spacer>
  </Container>
);

export const MeetAnAnimalContent: React.FC = () => (
  <Container>
    <Spacer direction="vertical" size="large">
      <div className="max-w-520 pt-24 mx-auto">
        <Spacer direction="vertical" size="large">
          <Heading level="h3" tag="h1">
            Meet an Animal
          </Heading>
          <Text>
            {<strong>How do I meet an animal?</strong>}
            {<br />}
            Our shelters and rescues are busy people! The first step to meeting a fur pal is showing them that you’re
            serious about adoption – and for them, that means filling out the adoption application form. It saves them
            (and you!) time.
          </Text>
          <Text>
            The next step is our shelters reviewing your application, and if all looks good, they might ask you to visit
            their shelter location. Or, if they use a pet foster network as many do, you’ll organise a time and place
            with a foster to meet the fluffer.
          </Text>
        </Spacer>
      </div>
      <div className="py-24">
        <div className="grid gap-48 grid-cols-2--md">
          <img className="w-full" src={Binoculars} alt="Meet an Animal" />
          <Spacer>
            <Text>
              {<strong>Why do I need to create an account to apply for an animal?</strong>}
              {<br />}
              Creating a MyHooman account has some fluffin&apos; great benefits. If you apply for an animal, you&apos;ll
              be able to follow the progress of your application, saving you calling the shelter to follow up.
            </Text>
            <Text>
              This means all the fur pals you&apos;ve favourited are saved for you to check back on or apply for later.
              This also means you’ll be able to spot when animals you didn&apos;t adopt find their own forever home.
              Fuzzin&apos; great!
            </Text>
            <Text>
              Lastly, when you create an account, you can opt in to receive emails of new animals that are good matches
              for you. Fluff pals in your inbox – yes please!
            </Text>
          </Spacer>
        </div>
      </div>
    </Spacer>
  </Container>
);

export const HelpfulTipsContent: React.FC = () => (
  <Container>
    <Spacer direction="vertical" size="large">
      <div className="max-w-520 pt-24 mx-auto">
        <Spacer direction="vertical" size="large">
          <Heading level="h3" tag="h1">
            Helpful tips for taking care of your new pet
          </Heading>
          <Text>
            <br />
            Congratulations! Whether you’ve adopted a playful pup, a cuddly cat or another animal, this exciting time
            marks the beginning of a special bond filled with love and companionship.
          </Text>
          <Text>
            To help ensure that you and your new pet embark on the right path together, it’s important to establish a
            smooth transition into home-life. From preparing your home, to setting routines and building trust, these
            factors will help to create a positive start and lifelong connection.
          </Text>
          <Text>Browse our resources below to discover top tips for taking care of your new fur-ever friend!</Text>
        </Spacer>
      </div>
      <div className="py-24">
        <div className="grid grid-cols-3--md faq-banner">
          <StackedPanel padded className="faq-banner--left" backgroundPalette="base" variant="secondary-400">
            <Heading level="h2" tag="h1" className="faq-banner--heading">
              Discover the right pet food for your newly adopted pet
            </Heading>
          </StackedPanel>
          <StackedPanel className="faq-banner--middle" backgroundPalette="secondary-600" variant="secondary-400">
            <div className="faq-banner--content">
              <Heading level="h5" tag="h5" className="faq-banner--content__header">
                Every pet has unique nutritional needs.
              </Heading>
              <Text className="faq-banner--content__text">
                Use Royal Canin’s online tool to identify the most optimal pet food for your new pet in just a few
                clicks.
              </Text>
              <Button
                className="faq-banner--content__button"
                href="https://www.royalcanin.com/nz/product-finder/tree"
                target="_blank"
              >
                Try now
              </Button>
            </div>
          </StackedPanel>
          <StackedPanel padded className="faq-banner--right" backgroundPalette="secondary" variant="secondary">
            <img className="w-full" src={PetFood} alt="What is MyHooman?" />
          </StackedPanel>
        </div>
      </div>
      <div className="py-24">
        <div className="grid gap-48 grid-cols-2--md">
          <Spacer>
            <img className="w-full" src={House} alt="Adopted Animal" />
          </Spacer>
          <Spacer>
            <Text className="faq--header">{<strong>Resources</strong>}</Text>
            <Anchor
              size="small"
              id="PET_CHECKLIST"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/checklist"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-checklist" />
            </Anchor>
            <Anchor
              id="PET_TRAINING_TIPS"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.pedigree.com/dog-care-articles/10-training-tips-your-new-dog"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-training-tips" />
            </Anchor>
            <Anchor
              id="PET_KEEP_HAPPY"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/cat-lifestyle/new-kitten-care"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-steps-to-keep-happy" />
            </Anchor>
            <Anchor
              id="ANIMAL_HOUSE"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/dog-lifestyle/how-to-intro-a-dog-to-a-cat"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-animal-house" />
            </Anchor>
            <Anchor
              id="WELCOME_TO_JUNGLE"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/cat-lifestyle/introduce-new-cat"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-welcome-to-jungle" />
            </Anchor>
            <Anchor
              id="UNEXPECTED_TODOS"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/pet-lifestyle/unexpected-advice-for-new-pet-parents"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-unexpected-todos" />
            </Anchor>
            <Anchor
              id="BUILD_CAT_TRUST"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/cat-behavior/how-to-get-a-cat-to-trust-you"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-build-cat-trust" />
            </Anchor>
            <Anchor
              id="LEARN_DOG_LANGUAGE"
              size="small"
              variant="primary"
              target="_blank"
              href="https://www.thewildest.com/dog-lifestyle/learn-your-dogs-language"
            >
              <FormattedMessage id="pages.faqs.already-adopted.links.pet-learn-dog-language" />
            </Anchor>
          </Spacer>
        </div>
      </div>
    </Spacer>
  </Container>
);

//// <reference types="googlemaps" />

import ClassNames from 'classnames';
import _compact from 'lodash/compact';
import _defaultTo from 'lodash/defaultTo';
import _find from 'lodash/find';
import _first from 'lodash/first';
import _flow from 'lodash/flow';
import _get from 'lodash/get';
import _join from 'lodash/join';
import React, { createRef, useEffect } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AutocompletionRequest } from 'react-google-places-autocomplete/build/GooglePlacesAutocomplete.types';
import { IndicatorComponentType } from 'react-select';

/*
 * Sample selected address
 * {
 *   description: '12 Madden Street, Auckland CBD, Auckland, New Zealand',
 *   matched_substrings: [
 *     { length: 2, offset: 0 },
 *     { length: 13, offset: 3 },
 *   ],
 *   place_id: 'ChIJ-cXZOfRHDW0RLMOqsYOekWI',
 *   reference: 'ChIJ-cXZOfRHDW0RLMOqsYOekWI',
 *   structured_formatting: {
 *     main_text: '12 Madden Street',
 *     main_text_matched_substrings: [
 *       { length: 2, offset: 0 },
 *       { length: 13, offset: 3 },
 *     ],
 *     secondary_text: 'Auckland CBD, Auckland, New Zealand',
 *   },
 *   terms: [
 *     { offset: 0, value: '12' },
 *     { offset: 3, value: 'Madden Street' },
 *     { offset: 18, value: 'Auckland CBD' },
 *     { offset: 32, value: 'Auckland' },
 *     { offset: 42, value: 'New Zealand' },
 *   ],
 *   types: ['street_address', 'geocode'],
 * }
 */
interface ISelectedAddress {
  description: string;
  matched_substrings: {
    length: number;
    offset: number;
  }[];
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: {
      length: number;
      offset: number;
    }[];
    secondary_text: string;
  };
  terms: {
    offset: number;
    value: string;
  }[];
  types: string[];
}

export interface IAddress {
  country: string;
  street: string;
  suburb: string;
  city: string;
  state: string;
  postcode: string;
  formattedAddress: string;
  latitude: number;
  longitude: number;
}

export interface FormFieldProps extends React.InputHTMLAttributes<any> {
  affix?: React.ReactNode;
  align?: 'left' | 'center' | 'right';
  loading?: boolean;
  success?: boolean;
  suffix?: React.ReactNode;
  apiKey?: string;
  countryCode?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onSelectAddress?: (address: IAddress | null) => void;
  ClearIndicator?: IndicatorComponentType<any, any> | null;
  bordered?: boolean;
  focusOnRender?: boolean;
}

const AddressAutocompleteField: React.FC<FormFieldProps> = ({
  apiKey,
  countryCode,
  className,
  onSelectAddress,
  placeholder,
  onFocus,
  onBlur,
  ClearIndicator,
  bordered,
  focusOnRender,
  ...props
}) => {
  const CLASS_NAMES = ClassNames('c-select-field', className);
  const selectRef = createRef<HTMLSelectElement>();

  useEffect(() => {
    if (focusOnRender && selectRef.current) selectRef.current.focus();
  }, []);

  const autocompletionRequest: AutocompletionRequest = {
    types: ['geocode'],
  };

  if (countryCode) {
    autocompletionRequest.componentRestrictions = {
      country: countryCode,
    };
  }

  const onChange = async (selectedOption: any) => {
    if (!onSelectAddress) {
      return;
    }

    if (!selectedOption) {
      onSelectAddress(null);
      return;
    }

    const address: ISelectedAddress = selectedOption.value;
    const placeId = address.place_id;

    if (!placeId) {
      console.error('Unable to retrieve place id within selected address', address);

      throw new Error('Unable to retrieve place id');
    }

    const response: google.maps.GeocoderResult[] = await geocodeByPlaceId(placeId);

    /**
     * Sample geocode result
     * {
     *   address_components: [
     *     { long_name: '12', short_name: '12', types: ['street_number'] },
     *     { long_name: 'Madden Street', short_name: 'Madden St', types: ['route'] },
     *     {
     *       long_name: 'Auckland CBD',
     *       short_name: 'Auckland CBD',
     *       types: ['political', 'sublocality', 'sublocality_level_1'],
     *     },
     *     { long_name: 'Auckland', short_name: 'Auckland', types: ['locality', 'political'] },
     *     { long_name: 'Auckland', short_name: 'Auckland', types: ['administrative_area_level_1', 'political'] },
     *     { long_name: 'New Zealand', short_name: 'NZ', types: ['country', 'political'] },
     *     { long_name: '1010', short_name: '1010', types: ['postal_code'] },
     *   ],
     *   formatted_address: '12 Madden Street, Auckland CBD, Auckland 1010, New Zealand',
     *   geometry: {
     *     location: { lat: -36.8421179, lng: 174.7565722 },
     *     location_type: 'ROOFTOP',
     *     viewport: {
     *       south: -36.84346688029149,
     *       west: 174.7552232197085,
     *       north: -36.8407689197085,
     *       east: 174.7579211802915,
     *     },
     *   },
     *   place_id: 'ChIJ-cXZOfRHDW0RLMOqsYOekWI',
     *   types: ['street_address'],
     * }
     */
    const firstResult = _first(response);

    if (!firstResult) {
      console.error('Unable to find place with id', placeId);

      throw new Error('Unable to find place with id ' + placeId);
    }

    const cc = firstResult.address_components;
    const ccget: (type: string) => string = _flow(
      (type) => _find(cc, { types: [type] }),
      (component) => _get(component, 'short_name'),
      (value) => _defaultTo(value, ''),
    );
    const ccgetl: (type: string) => string = _flow(
      (type) => _find(cc, { types: [type] }),
      (component) => _get(component, 'long_name'),
      (value) => _defaultTo(value, ''),
    );

    const street = address.structured_formatting.main_text;
    // const streetNumber = ccgetl('street_number');
    // const streetName = ccgetl('route');
    // const street = _join(_compact([streetNumber, streetName]), ' ');
    const suburb = ccget('sublocality_level_1');
    const city = ccgetl('administrative_area_level_3') || ccgetl('administrative_area_level_2') || ccgetl('locality');
    const state = ccget('administrative_area_level_1');
    const postcode = ccget('postal_code');
    const country = ccgetl('country');

    const formattedAddress = firstResult.formatted_address;

    const { lat: latitude, lng: longitude } = JSON.parse(JSON.stringify(firstResult.geometry.location));

    const result: IAddress = {
      country,
      street,
      suburb,
      city,
      state,
      postcode,
      formattedAddress,
      latitude,
      longitude,
    };

    onSelectAddress(result);
  };
  /**
   * GooglePlacesAutocomplete uses react-select lib, which currently throws a couple of critical errors
   * Hopefully it'll be fixes soon
   * (Anna, 17 Oct 2020)
   * https://github.com/JedWatson/react-select/issues/4094
   */
  return (
    <GooglePlacesAutocomplete
      apiKey={apiKey}
      onLoadFailed={console.error}
      minLengthAutocomplete={2}
      autocompletionRequest={autocompletionRequest}
      withSessionToken
      selectProps={{
        ref: selectRef,
        className: CLASS_NAMES,
        classNamePrefix: 'c-select-field',
        openMenuOnClick: false,
        blurInputOnSelect: true,
        styles: {
          container: (provided: any) => ({
            ...provided,
            backgroundColor: 'transparent',
            padding: 0,
          }),
        },
        components: {
          DropdownIndicator: null,
          ClearIndicator: ClearIndicator || null,
        },
        onChange,
        onFocus,
        onBlur,
        defaultInputValue: '',
        placeholder,
        isClearable: true,
      }}
    />
  );
};

export default AddressAutocompleteField;

import { ScopeType, makeLoggers } from '@colensobbdo/shelter-management-frontend-integration';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loading from '../components/Loading';
import routes from '../configs/routes';
import UnauthorizedPage from '../pages/UnauthorizedPage/UnauthorizedPage';
import { hasPermission, isAuthenticated } from '../store/auth/selectors';

const { log } = makeLoggers('withAuthentication', false);

export function withAuthentication<T>(WrappedComponent: React.ComponentType<T>, scope?: ScopeType): React.FC<T> {
  return (props: T) => {
    const isLoggedIn = useSelector(isAuthenticated);
    const hasAccess = useSelector(hasPermission(scope));

    log('checking access', { isLoggedIn, scope, hasAccess });

    if (!isLoggedIn) {
      return <Redirect to={routes.pages.login} />;
    }

    if (!hasAccess) {
      return <UnauthorizedPage />;
    }

    return <WrappedComponent {...props} />;
  };
}

export default withAuthentication;

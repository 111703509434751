import {
  setAccessTokenGetter,
  setLogoutHandler,
  setRefreshTokenGetter,
  setRefreshTokenSetter,
} from '@colensobbdo/shelter-management-frontend-integration';
import { Action, ThunkAction, combineReducers, configureStore } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';

import { activationReducer } from './activation/reducers';
import { authReducer } from './auth/reducers';
import { getAccessToken, getRefreshToken } from './auth/selectors';
import { thunkSaveTokens } from './auth/thunks';
import { favoriteReducer } from './favorites/reducers';
import { logout } from './logout/actions';
import { personReducer } from './person/reducers';
import { preferencesReducer } from './preferences/reducers';
import { registrationReducer } from './registration/reducers';
import { searchReducer } from './search/reducers';
import { shelterReducer } from './shelter/reducers';
import { sheltersReducer } from './shelters/reducers';
import { usernameReducer } from './username/reducers';

const rootReducer = combineReducers({
  activation: activationReducer,
  auth: authReducer,
  username: usernameReducer,
  person: personReducer,
  registration: registrationReducer,
  search: searchReducer,
  shelter: shelterReducer,
  favorite: favoriteReducer,
  preferences: preferencesReducer,
  shelters: sheltersReducer,
});

type State = ReturnType<typeof rootReducer>;

const persistConfig: PersistConfig<State> = {
  key: 'root-b2c',
  storage,
  blacklist: ['registration', 'shelters'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: any = [thunkMiddleware];
export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, void, Action>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type GetState = typeof store.getState;

// allow api to retrieve access/refresh token
setAccessTokenGetter(() => getAccessToken(store.getState()));
setRefreshTokenGetter(() => getRefreshToken(store.getState()));
setRefreshTokenSetter((tokens) => store.dispatch<any>(thunkSaveTokens(tokens)));
setLogoutHandler(() => store.dispatch<any>(logout()));

import { AccessLevelType } from '@colensobbdo/shelter-management-frontend-integration';

import { AUTH_DATA_CLEAR, AUTH_DATA_UPDATE, AuthActionTypes, AuthState } from './types';

const initialState: AuthState = {
  accessToken: null,
  accessTokenExpires: null,
  refreshToken: null,
  refreshTokenExpires: null,
  scope: [],
  accessLevel: AccessLevelType.Level5,
};

/**
 * @param state
 * @param action
 */
export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case AUTH_DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import { ensureInt } from '@colensobbdo/shelter-management-frontend-integration';
import _compact from 'lodash/compact';
import _get from 'lodash/get';
import _indexOf from 'lodash/indexOf';
import _without from 'lodash/without';

import {
  FAVORITE_ADD_ANIMAL,
  FAVORITE_CLEAR,
  FAVORITE_REMOVE_ANIMAL,
  FAVORITE_REPLACE,
  FAVORITE_TOGGLE_ANIMAL,
  FavoriteActionTypes,
  FavoriteState,
} from './types';

const initialState: FavoriteState = {
  animalIds: [],
  toDelete: [],
};

/**
 * @param state
 * @param action
 */
export function favoriteReducer(state = initialState, action: FavoriteActionTypes): FavoriteState {
  const animalId = ensureInt(_get(action, 'payload'));
  const hasAnimal = _indexOf(state.animalIds, animalId) !== -1;
  const filteredAnimalIds = _without(state.animalIds, animalId);
  const filteredToDelete = _without(state.toDelete, animalId);

  switch (action.type) {
    case FAVORITE_CLEAR:
      return {
        ...initialState,
      };
    case FAVORITE_REPLACE:
      return {
        ...initialState,
        animalIds: _compact(action.payload),
      };
    case FAVORITE_ADD_ANIMAL:
    case FAVORITE_REMOVE_ANIMAL:
    case FAVORITE_TOGGLE_ANIMAL:
      //
      // append this to the favorited animal list
      //

      if (action.type === FAVORITE_ADD_ANIMAL || (FAVORITE_TOGGLE_ANIMAL && !hasAnimal)) {
        return {
          ...state,
          animalIds: [...filteredAnimalIds, animalId],
          toDelete: filteredToDelete,
        };
      }

      //
      // remove this from the favorited animal list
      // and mark it as to-delete from the api side
      //

      return {
        ...state,
        animalIds: filteredAnimalIds,
        toDelete: [...filteredToDelete, animalId],
      };
    default:
      return state;
  }
}

import { AccessLevelType } from '@colensobbdo/shelter-management-frontend-integration';

// state

export type AuthState = {
  accessToken: string | null;
  accessTokenExpires: Date | null;
  refreshToken: string | null;
  refreshTokenExpires: Date | null;
  scope: string[];
  accessLevel: AccessLevelType;
};

// action

export const AUTH_DATA_UPDATE = 'AUTH_DATA_UPDATE';
export const AUTH_DATA_CLEAR = 'AUTH_DATA_CLEAR';

export type AuthDataUpdatePayload = Omit<AuthState, 'scope' | 'accessLevel'> | AuthState;

interface AuthDataUpdateAction {
  type: typeof AUTH_DATA_UPDATE;
  payload: Partial<AuthDataUpdatePayload>;
}

interface AuthDataClearAction {
  type: typeof AUTH_DATA_CLEAR;
}

export type AuthActionTypes = AuthDataUpdateAction | AuthDataClearAction;

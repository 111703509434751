import React from 'react';

const FavouritesCtaImage: React.FC<{}> = () => {
  return (
    <svg width="112" height="63" viewBox="0 0 112 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="25" y="6" width="81" height="48" rx="24" fill="#FF9EB0" />
      </g>
      <g filter="url(#filter1_d)">
        <path
          d="M67.8033 22.3117C67.226 21.6826 66.5406 21.1835 65.7863 20.8429C65.0319 20.5024 64.2233 20.3271 63.4068 20.3271C62.5902 20.3271 61.7816 20.5024 61.0273 20.8429C60.2729 21.1835 59.5875 21.6826 59.0102 22.3117L57.8122 23.6169L56.6142 22.3117C55.4481 21.0414 53.8667 20.3278 52.2176 20.3278C50.5686 20.3278 48.9871 21.0414 47.8211 22.3117C46.6551 23.582 46 25.3049 46 27.1014C46 28.8978 46.6551 30.6207 47.8211 31.891L49.0191 33.1962L57.8122 42.7754L66.6053 33.1962L67.8033 31.891C68.3808 31.2621 68.839 30.5155 69.1515 29.6936C69.4641 28.8718 69.625 27.9909 69.625 27.1014C69.625 26.2118 69.4641 25.3309 69.1515 24.5091C68.839 23.6873 68.3808 22.9406 67.8033 22.3117Z"
          fill="white"
        />
        <path
          d="M67.8033 22.3117C67.226 21.6826 66.5406 21.1835 65.7863 20.8429C65.0319 20.5024 64.2233 20.3271 63.4068 20.3271C62.5902 20.3271 61.7816 20.5024 61.0273 20.8429C60.2729 21.1835 59.5875 21.6826 59.0102 22.3117L57.8122 23.6169L56.6142 22.3117C55.4481 21.0414 53.8667 20.3278 52.2176 20.3278C50.5686 20.3278 48.9871 21.0414 47.8211 22.3117C46.6551 23.582 46 25.3049 46 27.1014C46 28.8978 46.6551 30.6207 47.8211 31.891L49.0191 33.1962L57.8122 42.7754L66.6053 33.1962L67.8033 31.891C68.3808 31.2621 68.839 30.5155 69.1515 29.6936C69.4641 28.8718 69.625 27.9909 69.625 27.1014C69.625 26.2118 69.4641 25.3309 69.1515 24.5091C68.839 23.6873 68.3808 22.9406 67.8033 22.3117Z"
          stroke="white"
          strokeWidth="1.95"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path d="M86.2412 36.5H84.1143V26.2256L80.9766 27.2979V25.4961L85.9688 23.6592H86.2412V36.5Z" fill="#FFFAE0" />
      <path
        opacity="0.8"
        d="M47.3334 4.1497C46.8074 3.50211 46.169 2.9758 45.4548 2.60082C44.7406 2.22585 43.9644 2.00957 43.1708 1.96433C42.3771 1.91909 41.5814 2.04579 40.8292 2.33718C40.077 2.62857 39.383 3.07894 38.7868 3.66257L37.5496 4.87321L36.4579 3.52983C35.3954 2.22233 33.8981 1.43645 32.2953 1.3451C30.6925 1.25374 29.1155 1.86439 27.9113 3.0427C26.7071 4.22101 25.9743 5.87046 25.8742 7.62819C25.774 9.38593 26.3146 11.108 27.3771 12.4155L28.4688 13.7588L36.4811 23.6187L45.5619 14.7331L46.7991 13.5225C47.3956 12.9391 47.8825 12.2339 48.2321 11.4472C48.5818 10.6604 48.7873 9.80741 48.8369 8.93701C48.8865 8.06661 48.7793 7.19582 48.5213 6.3744C48.2633 5.55298 47.8596 4.79702 47.3334 4.1497Z"
        fill="#FFE0E5"
        stroke="#FFE0E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.5"
        d="M14.2225 7.86479C13.9111 7.48138 13.5332 7.16978 13.1103 6.94777C12.6875 6.72577 12.2279 6.59772 11.758 6.57093C11.2881 6.54415 10.8171 6.61916 10.3717 6.79168C9.92636 6.9642 9.51547 7.23084 9.1625 7.57638L8.43 8.29315L7.78368 7.49779C7.15462 6.72368 6.26811 6.2584 5.31917 6.20432C4.37023 6.15023 3.43659 6.51176 2.72365 7.20939C2.01071 7.90701 1.57685 8.88357 1.51754 9.92424C1.45822 10.9649 1.7783 11.9845 2.40736 12.7586L3.05368 13.5539L7.79742 19.3915L13.1737 14.1307L13.9062 13.414C14.2594 13.0686 14.5476 12.6511 14.7547 12.1853C14.9617 11.7195 15.0833 11.2145 15.1127 10.6991C15.1421 10.1838 15.0786 9.66826 14.9258 9.18193C14.7731 8.69561 14.5341 8.24804 14.2225 7.86479Z"
        fill="#FFE0E5"
        stroke="#FFE0E5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="19"
          y="3"
          width="93"
          height="60"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="39.0254"
          y="16.3521"
          width="37.575"
          height="36.3983"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

export default FavouritesCtaImage;

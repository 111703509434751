import {
  AnimalMatching,
  CountryIdType,
  IUserGroup,
  LanguageIdType,
  PersonIdType,
  ShelterPersonIdType,
} from '@colensobbdo/shelter-management-frontend-integration';

// state

export interface PersonState {
  personId: PersonIdType | null;
  shelterPersonId: ShelterPersonIdType | null;
  countryId: CountryIdType | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  photoUrl: string | null;
  userTypes: string[] | null;
  userGroups: IUserGroup[] | null;
  isActive: boolean;
  languageId: LanguageIdType | null;
  dob: Date | null;
  // TODO: remove this
  matchParams: AnimalMatching.SearchRequest | null;
}

// action

export const PERSON_DATA_UPDATE = 'PERSON_DATA_UPDATE';
export const PERSON_DATA_CLEAR = 'PERSON_DATA_CLEAR';

export type PersonDataUpdatePayload = Partial<PersonState>;

interface PersonDataUpdateAction {
  type: typeof PERSON_DATA_UPDATE;
  payload: PersonDataUpdatePayload;
}

interface PersonDataClearAction {
  type: typeof PERSON_DATA_CLEAR;
}

export type PersonActionTypes = PersonDataUpdateAction | PersonDataClearAction;

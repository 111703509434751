const _trim = require('lodash/trim');

/*
 * Be aware that if you use ES6 module imports in your entry file
 * the __webpack_public_path__ assignment will be done after the imports.
 * In such cases, you'll have to move the public path assignment to its
 * own dedicated module and then import it on top of your entry.js:
 */
const ASSET_PATH = ((global as any).config || {}).assetPath || '';

__webpack_public_path__ = `${_trim(ASSET_PATH, '/')}/`;

export default {};

import {
  PREFERENCE_CLEAR,
  PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN,
  PREFERENCE_GUOH_MODAL_SHOWN,
  PREFERENCE_REGISTER_ACTION_LAYER_SHOWN,
  PreferenceActionTypes,
  PreferencesState,
} from './types';

const initialState: PreferencesState = {
  didShowRegisterActionLayer: false,
  didShowFavouriteActionLayer: false,
  didShowGUOHModal: false,
};

/**
 * @param state
 * @param action
 */
export function preferencesReducer(state = initialState, action: PreferenceActionTypes): PreferencesState {
  switch (action.type) {
    case PREFERENCE_REGISTER_ACTION_LAYER_SHOWN:
      return {
        ...state,
        didShowRegisterActionLayer: true,
      };

    case PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN:
      return {
        ...state,
        didShowFavouriteActionLayer: true,
      };

    case PREFERENCE_GUOH_MODAL_SHOWN:
      return {
        ...state,
        didShowGUOHModal: true,
      };

    case PREFERENCE_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

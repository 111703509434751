const ignoredErrors = [
  'Warning: React does not recognize the `%s` prop on a DOM element.',
  'Warning: Received `%s` for a non-boolean attribute `%s`.',
  "Warning: Can't perform a React state update on an unmounted component.",
  'Warning: Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code.',
];

const error = console.error;

console.error = function (...args: any[]) {
  if (typeof args[0] === 'string')
    for (const _ignored of ignoredErrors) {
      if (args[0].includes(_ignored)) return console.debug(...args);
    }

  error(...args);
};

export default null;

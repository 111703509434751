import { SHELTER_DATA_CLEAR, SHELTER_DATA_UPDATE, ShelterActionTypes, ShelterDataUpdatePayload } from './types';

export function shelterDataUpdate(data: ShelterDataUpdatePayload): ShelterActionTypes {
  return {
    payload: data,
    type: SHELTER_DATA_UPDATE,
  };
}

export function shelterDataClear(): ShelterActionTypes {
  return {
    type: SHELTER_DATA_CLEAR,
  };
}

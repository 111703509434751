// state

import { IShelterListItem } from '@colensobbdo/shelter-management-frontend-integration';

export type SheltersState = {
  list: IShelterListItem[];
};

// action

export const SHELTERS_STORE_NEW_SHELTER = 'SHELTERS_STORE_NEW_SHELTER';
export const SHELTERS_CLEAR_DATA = 'SHELTERS_CLEAR_DATA';

export type SheltersStoreNewShelterActionPayload = IShelterListItem;

interface SheltersStoreNewShelterAction {
  type: typeof SHELTERS_STORE_NEW_SHELTER;
  payload: SheltersStoreNewShelterActionPayload;
}
interface SheltersClearDataAction {
  type: typeof SHELTERS_CLEAR_DATA;
}

export type SheltersActionTypes = SheltersStoreNewShelterAction | SheltersClearDataAction;

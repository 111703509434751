import { ScopeType } from '@colensobbdo/shelter-management-frontend-integration';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import routes from '../../configs/routes';
import withAuthentication from '../../hoc/withAuthentication';

interface IPrivateRouteProps extends RouteProps {
  component: any;
  scope?: ScopeType;
}

/**
 * @param props
 * @constructor
 */
const PrivateRoute = (props: IPrivateRouteProps) => {
  const { component, scope, ...rest } = props;

  const WithAuthentication = withAuthentication(component, scope);

  // @ts-ignore
  return <Route {...rest} render={(props) => <WithAuthentication {...props} />} />;
};

export default PrivateRoute;

import { AnimalIdType } from '@colensobbdo/shelter-management-frontend-integration';

export type ActivationState = {
  startApplicationAnimalId?: AnimalIdType;
};

export const ACTIVATION_SET_START_APPLICATION_ANIMAL_ID = 'ACTIVATION_SET_START_APPLICATION_ANIMAL_ID';

interface ActivationSetStartApplicationAnimalIdAction {
  type: typeof ACTIVATION_SET_START_APPLICATION_ANIMAL_ID;
  payload?: AnimalIdType;
}

export type ActivationTypes = ActivationSetStartApplicationAnimalIdAction;

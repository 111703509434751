import React from 'react';

const DogPawBottomImage2: React.FC<{}> = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 103 215"
      preserveAspectRatio="xMidYMid meet"
      className="dropDogPawBottomImage2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{`.dropDogPawBottomImage2 { margin-bottom: -32px }`}</style>
      <path
        fillRule="evenodd"
        clipRule="evenOdd"
        d="M19.4215 38.2201C16.146 33.6587 16.9811 27.2633 21.4566 23.6838C26.0378 20.0362 32.7269 20.7791 36.4196 25.396C36.6945 25.7416 36.9711 26.0936 37.2492 26.452C35.4804 22.0494 36.8345 16.8202 40.8309 13.895C45.5569 10.4357 52.2384 11.4666 55.6976 16.1926C74.5281 41.8659 86.7554 73.963 92.9581 95.6372C94.7554 101.208 96.5033 107.429 98.0127 114.192C105.86 149.375 103.945 184.54 92.4466 215.934C86.7154 231.609 78.6317 246.448 68.2579 260.282L28.0065 215.153C31.7569 208.936 34.8223 202.47 37.293 195.827C53.4059 151.876 36.3219 109.479 30.8438 97.7723C30.5505 97.3513 30.2842 96.9043 30.0483 96.4324C28.7687 93.814 27.1404 90.4943 25.2509 86.8017C19.7883 89.9892 12.7915 88.2502 9.51892 82.8145C6.21814 77.3319 7.9399 70.2422 13.4225 66.9415C13.5361 66.8736 13.7154 66.7672 13.9556 66.6284C10.5409 61.1795 6.91569 56.0569 3.29786 52.078C-0.654694 47.6962 -0.325936 40.973 4.0201 37.0656C8.40192 33.1131 15.1251 33.4418 19.0325 37.7879C19.1624 37.931 19.292 38.0751 19.4215 38.2201ZM52.4313 51.484C52.0074 50.5509 51.5698 49.6232 51.1203 48.7026C51.6224 49.6228 52.1166 50.5451 52.603 51.4688C52.5458 51.4737 52.4885 51.4787 52.4313 51.484Z"
        fill="#4353CD"
      />
      <path
        d="M59.3151 51.6515C56.4898 46.5231 47.8278 45.876 39.9679 50.2061C32.108 54.5362 28.0267 62.2039 30.852 67.3323C33.6774 72.4607 42.3395 73.1079 50.1993 68.7778C58.0592 64.4476 62.1405 56.7799 59.3151 51.6515Z"
        fill="#D9F9E4"
      />
      <path
        d="M53.4983 23.5037C51.7459 20.3276 48.4281 18.7996 46.0878 20.0908C43.7475 21.3821 43.2709 25.0036 45.0233 28.1797C46.7757 31.3559 50.0935 32.8839 52.4338 31.5927C54.7741 30.3014 55.2507 26.6799 53.4983 23.5037Z"
        fill="#D9F9E4"
      />
      <path
        d="M35.5504 33.2923C33.6874 29.8143 30.0398 28.1397 27.4032 29.552C24.7667 30.9643 24.1396 34.9286 26.0027 38.4066C27.8657 41.8846 31.5133 43.5592 34.1499 42.1469C36.7864 40.7346 37.4134 36.7702 35.5504 33.2923Z"
        fill="#D9F9E4"
      />
      <path
        d="M18.1977 45.1289C15.7727 41.8566 11.8588 40.6476 9.45567 42.4284C7.05259 44.2093 7.07037 48.3057 9.49538 51.5779C11.9204 54.8502 15.8343 56.0593 18.2374 54.2784C20.6405 52.4975 20.6227 48.4012 18.1977 45.1289Z"
        fill="#D9F9E4"
      />
      <path
        d="M80.6559 22.0772C80.5127 22.2579 80.3602 22.3575 80.1174 22.3857C79.7127 22.4325 79.3872 22.1011 79.3403 21.6964C78.7608 9.25452 66.4074 5.64051 65.8579 5.49908C65.4796 5.41985 65.2256 4.9981 65.3407 4.57465C65.4199 4.19636 65.8416 3.94245 66.2651 4.05746C66.3912 4.08387 80.141 8.06935 80.8067 21.6086C80.8254 21.7705 80.7632 21.9418 80.6559 22.0772Z"
        fill="#232A47"
      />
      <path
        d="M84.8653 14.458C84.7222 14.6386 84.5697 14.7383 84.3268 14.7664C83.9221 14.8133 83.5967 14.4819 83.5498 14.0772C83.1492 5.30574 74.445 2.74561 74.0667 2.66637C73.6884 2.58714 73.4345 2.16539 73.5495 1.74194C73.6287 1.36365 74.0505 1.10974 74.4739 1.22476C74.6 1.25117 84.5746 4.15636 85.0161 13.9894C85.0349 14.1513 84.9727 14.3225 84.8653 14.458Z"
        fill="#232A47"
      />
    </svg>
  );
};

export default DogPawBottomImage2;

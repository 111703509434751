interface GtmWindow extends Window {
  dataLayer?: Array<unknown>;
}

export interface GtmEventArgs {
  event: string;
  [name: string]: unknown;
}

export const pushGtmEvent = (args: GtmEventArgs): void => {
  (window as GtmWindow).dataLayer?.push(args);
};

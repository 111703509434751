import { getFullNameFormatted, getInitialsFormatted } from '@colensobbdo/shelter-management-frontend-integration';
import _map from 'lodash/map';

import { RootState } from '../store';

export const getPersonId = (state: RootState) => state.person.personId;
export const getShelterPersonId = (state: RootState) => state.person.shelterPersonId;
export const getCountryId = (state: RootState) => state.person.countryId;
export const getEmail = (state: RootState) => state.person.email;
export const getDateOfBirth = (state: RootState) => state.person.dob;
export const getFirstName = (state: RootState) => state.person.firstName;
export const getLastName = (state: RootState) => state.person.lastName;
export const getFullName = (state: RootState) => getFullNameFormatted(getFirstName(state), getLastName(state));
export const getInitials = (state: RootState) => getInitialsFormatted(getFirstName(state), getLastName(state));
export const getAvatarUrl = (state: RootState) => state.person.photoUrl;
export const getUserPhone = (state: RootState) => state.person.phone;
export const getAccountIsActive = (state: RootState) => state.person.isActive;
export const getUserLanguageId = (state: RootState) => state.person.languageId;
export const getUserGroups = (state: RootState) => state.person.userGroups;
export const getUserSummary = (state: RootState) => ({
  id: getPersonId(state),
  firstName: getFirstName(state),
  lastName: getLastName(state),
  email: getEmail(state),
  dateOfBirth: getDateOfBirth(state),
});
export const getUserGroupLabels = (state: RootState) => _map(getUserGroups(state), (group) => group.name);

import DogHumanArmsImage from './DogHumanArmsImage';
import DogPawBottomImage from './DogPawBottomImage';
import DogPawBottomImage2 from './DogPawBottomImage2';
import DogPawHumanHandBottomImage from './DogPawHumanHandBottomImage';
import FavouritesCtaImage from './FavouritesCtaImage';
import FavouritesEmptyImage from './FavouritesEmptyImage';
import LocationImage from './LocationImage';
import OnboardingIntroImage from './OnboardingIntroImage';

export type ImageTypes = {
  FavouritesCtaImage: typeof FavouritesCtaImage;
  FavouritesEmptyImage: typeof FavouritesEmptyImage;
  DogHumanArmsImage: typeof DogHumanArmsImage;
  DogPawHumanHandBottomImage: typeof DogPawHumanHandBottomImage;
  OnboardingIntroImage: typeof OnboardingIntroImage;
  DogPawBottomImage: typeof DogPawBottomImage;
  DogPawBottomImage2: typeof DogPawBottomImage2;
  LocationImage: typeof LocationImage;
};

const Images = {} as ImageTypes;
Images.FavouritesCtaImage = FavouritesCtaImage;
Images.FavouritesEmptyImage = FavouritesEmptyImage;
Images.DogHumanArmsImage = DogHumanArmsImage;
Images.DogPawHumanHandBottomImage = DogPawHumanHandBottomImage;
Images.OnboardingIntroImage = OnboardingIntroImage;
Images.DogPawBottomImage = DogPawBottomImage;
Images.DogPawBottomImage2 = DogPawBottomImage2;
Images.LocationImage = LocationImage;

export default Images;

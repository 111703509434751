import { AccessLevelType, ScopeType } from '@colensobbdo/shelter-management-frontend-integration';
import _indexOf from 'lodash/indexOf';
import moment from 'moment';

import { getAccountIsActive } from '../person/selectors';
import { RootState } from '../store';

export const getAccessToken = (state: RootState) => {
  const { accessToken, accessTokenExpires } = state.auth || {};

  if (!accessToken) {
    return null;
  }

  if (!!accessTokenExpires && moment(accessTokenExpires).isBefore()) {
    return null;
  }

  return accessToken;
};

export const getRefreshToken = (state: RootState) => {
  const { refreshToken, refreshTokenExpires } = state.auth || {};

  if (!refreshToken) {
    return null;
  }

  if (refreshTokenExpires && moment(refreshTokenExpires).isBefore()) {
    return null;
  }

  return refreshToken;
};

export const isAuthenticated = (state: RootState) => !!getRefreshToken(state) && getAccountIsActive(state);

export const getAccessLevel = (state: RootState) => state.auth.accessLevel;

export const hasAccessLevel4 = (state: RootState) => getAccessLevel(state) <= AccessLevelType.Level4;
export const hasAccessLevel3 = (state: RootState) => getAccessLevel(state) <= AccessLevelType.Level3;
export const hasAccessLevel2 = (state: RootState) => getAccessLevel(state) <= AccessLevelType.Level2;
export const hasAccessLevel1 = (state: RootState) => getAccessLevel(state) <= AccessLevelType.Level1;

export const getAccessScope = (state: RootState) => state.auth.scope;
export const hasPermission = (scope: ScopeType | undefined) => (state: RootState) =>
  scope ? _indexOf(getAccessScope(state), scope) !== -1 : true;

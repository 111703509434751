import {
  CLEAR_REGISTRATION,
  RegistrationAction,
  RegistrationPageStep,
  RegistrationState,
  SET_REGISTRATION_ACCEPT_TERMS,
  SET_REGISTRATION_OPT_IN_ALL_UPDATES,
  SET_REGISTRATION_OPT_IN_MARKETING,
  SET_REGISTRATION_STEP,
  SET_REGISTRATION_USER,
} from './types';

const initialState: RegistrationState = {
  step: RegistrationPageStep.CreatePerson,
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: new Date(),
  password: '',
  countryId: -1,
  acceptTerms: false,
  optInAllUpdates: false,
  optInMarketingMyHooman: false,
  optInMarketingMarsAndAffiliates: false,
};

/**
 * @param state
 * @param action
 */
export function registrationReducer(state = initialState, action: RegistrationAction): RegistrationState {
  switch (action.type) {
    case SET_REGISTRATION_STEP:
      if (action.payload === RegistrationPageStep.CreatePerson) {
        return {
          ...initialState,
        };
      }
      return {
        ...state,
        step: action.payload,
      };

    case SET_REGISTRATION_USER:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        dateOfBirth: action.payload.dateOfBirth,
        password: action.payload.password,
        countryId: action.payload.countryId,
      };

    case SET_REGISTRATION_ACCEPT_TERMS:
      return {
        ...state,
        acceptTerms: action.payload,
      };

    case SET_REGISTRATION_OPT_IN_ALL_UPDATES:
      return {
        ...state,
        optInAllUpdates: action.payload,
      };

    case SET_REGISTRATION_OPT_IN_MARKETING:
      return {
        ...state,
        optInMarketingMyHooman: action.payload.optInMarketingMyHooman,
        optInMarketingMarsAndAffiliates: action.payload.optInMarketingMarsAndAffiliates,
      };

    case CLEAR_REGISTRATION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

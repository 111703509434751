import { configuration } from '@colensobbdo/shelter-management-frontend-integration'; // init the api client
import _attempt from 'lodash/attempt';
import _extend from 'lodash/extend';
import _get from 'lodash/get';
import _isError from 'lodash/isError';

export type EnvironmentType = 'Development' | 'Staging' | 'UAT' | 'Production';

// Development  = http://localhost:9000/
// Staging      = https://ped-sms.dev.levo.com.au/
// UAT          = https://uat.myhooman.com/
// Production   = https://shelter.myhooman.com/

interface IConfig {
  readonly environment: EnvironmentType;
  readonly assetPath: string;
  readonly apiEndpoint: string;
  readonly googleMapsPlacesApiKey: string;
  readonly timezone: string;
  readonly locale: string;
  readonly version: string;
}

const browserTimezone = _attempt(() => Intl.DateTimeFormat().resolvedOptions().timeZone);
const browserLocale = _get(global, 'navigator.userLanguage') || _get(global, 'navigator.language');

const DEFAULTS: IConfig = {
  environment: 'Development',
  assetPath: '/',
  apiEndpoint: process.env.API_BASE_URL || '/',
  googleMapsPlacesApiKey: process.env.GMAPS_PLACES_API_KEY || '',
  timezone: !_isError(browserTimezone) ? browserTimezone : 'Pacific/Auckland',
  locale: browserLocale || 'en',
  version: process.env.APP_VERSION || 'unknown',
};

// Overwrite default config in QA, UAT, Production, with config from BE index.html:
// <script>
//   window.config = {
//     environment: '',
//     assetPath: '',
//     apiEndpoint: '',
//     breedDetectionApiKey: '',
//     googleMapsPlacesApiKey: '',
//   };
// </script>
const CONFIG: IConfig = _extend({}, DEFAULTS, (global as any).config || {});

const { apiEndpoint, timezone, locale } = CONFIG;
configuration.set({ apiEndpoint, timezone, locale });

export const IS_DEV = CONFIG.environment === 'Development' || CONFIG.environment === 'Staging';
export const IS_UAT = CONFIG.environment === 'UAT';
export const IS_PROD = CONFIG.environment === 'Production';

if (IS_DEV) {
  console.log('CONFIG', CONFIG);
  console.log('API CONFIG', configuration.get());
}

export const getGoogleOptimizeExperimentID = (): string => {
  if (IS_DEV) {
    return 'H0Nu7IViRfig1_IX_SqNtA';
  }
  if (IS_UAT) {
    return 'wZWOOocsSuu9yghbMOTSYA';
  }
  if (IS_PROD) {
    return 'C6wHL8QdRV-O3pCrbexiDw';
  }

  throw new Error('Google Optimize variant ID not found for the current environment');
};

declare global {
  interface Window {
    dataLayer?: Array<unknown>;
    google_optimize?: any;
  }
}

export * from './i18n';

export default CONFIG;

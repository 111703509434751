import {
  Button,
  Header as HeaderComponent,
  Icons,
  Logo,
  Row,
  useActionLayer,
} from '@colensobbdo/shelter-management-frontend';
import { ICoordinates } from '@colensobbdo/shelter-management-frontend-integration';
import LocationActionLayer from 'components/LocationActionLayer';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { searchStoreNewCoordinate } from 'store/search/actions';

import routes from '../../configs/routes';
import FavoriteCountButton from '../FavoriteCountButton';

type Props = {
  block?: boolean;
  center?: boolean;
  children?: React.ReactNode;
  enableBackButton?: boolean;
  enableLocationButton?: boolean;
  enableFavouritesButton?: boolean;
  affix?: React.ReactNode;
  suffix?: React.ReactNode;
  size?: 'large' | 'full';
  onPressBack?: () => void;
  openLocationOnRender?: boolean;
  openLocationOnRequest?: boolean;
  onLocationIgnored?: () => void;
};

const { ChevronLeftIcon, LocationIcon } = Icons;

const Header: React.FC<Props> = ({ enableFavouritesButton = true, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    open: locationActionLayerOpened,
    openActionLayer: openLocationActionLayer,
    closeActionLayer: closeLocationActionLayer,
  } = useActionLayer();

  useEffect(() => {
    if (props.openLocationOnRender && !locationActionLayerOpened) openLocationActionLayer();
  }, []);

  useEffect(() => {
    if (props.openLocationOnRequest && !locationActionLayerOpened) openLocationActionLayer();
  }, [props.openLocationOnRequest, openLocationActionLayer, locationActionLayerOpened]);

  const _renderAffix = () => {
    if (props.enableBackButton)
      return (
        <Button onClick={() => (props.onPressBack ? props.onPressBack() : history.goBack())} icon text>
          <ChevronLeftIcon />
        </Button>
      );

    return props.affix;
  };

  const _renderLocationButton = () => {
    return (
      <Button className="mr-8 hover hover--scale110" onClick={openLocationActionLayer} icon text>
        <LocationIcon />
      </Button>
    );
  };

  const _renderSuffix = () => {
    if (props.suffix) return props.suffix;

    return (
      <Row>
        <div className="flex-center">
          {props.enableLocationButton && _renderLocationButton()}
          {enableFavouritesButton && <FavoriteCountButton />}
        </div>
      </Row>
    );
  };

  const _handleLocationSelected = (coordinates: ICoordinates) => {
    dispatch(searchStoreNewCoordinate(coordinates));
  };

  return (
    <>
      <HeaderComponent {...props} affix={_renderAffix()} suffix={_renderSuffix()}>
        {props.children ? (
          props.children
        ) : (
          <Link to={routes.pages.root}>
            <Logo width="100%" />
          </Link>
        )}
      </HeaderComponent>
      <LocationActionLayer
        open={locationActionLayerOpened}
        handleClose={() => {
          closeLocationActionLayer();
          props.onLocationIgnored && props.onLocationIgnored();
        }}
        handleAction={_handleLocationSelected}
      />
    </>
  );
};

Header.defaultProps = {
  enableBackButton: false,
};

export default Header;

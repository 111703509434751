import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SUPPORT_EMAIL, SUPPORT_PHONE_NUMERIC, SUPPORT_PHONE_READABLE } from '../../../../configs/support';
import { AnimalMatchingContent, HelpfulTipsContent, MeetAnAnimalContent, WhatIsMyHoomanContent } from './FaqContents';
import { FaqType } from './types';

export const FaqSections = [
  {
    category: 'faq',
    title: <FormattedMessage id={'pages.faqs.category.faq.title'} />,
  },
  {
    category: 'usage',
    title: <FormattedMessage id={'pages.faqs.category.usage.title'} />,
  },
  {
    category: 'adopted',
    title: <FormattedMessage id={'pages.faqs.category.adopted.title'} />,
  },
  {
    category: 'contact',
    title: <FormattedMessage id={'pages.faqs.category.contact.title'} />,
  },
];

export const Faqs: FaqType[] = [
  {
    id: 'landing',
    route: '',
    title: <FormattedMessage id={'pages.faqs.page-title'} />,
    category: 'landing',
  },
  {
    id: 'what-is-myhooman',
    route: 'what-is-myhooman',
    title: <FormattedMessage id={'pages.faqs.question.what-is-myhooman.title'} />,
    content: <WhatIsMyHoomanContent />,
    category: 'faq',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'animal-matching',
    route: 'animal-matching',
    title: <FormattedMessage id={'pages.faqs.question.animal-matching.title'} />,
    content: <AnimalMatchingContent />,
    category: 'usage',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'meet-an-animal',
    route: 'meet-an-animal',
    title: <FormattedMessage id={'pages.faqs.question.meet-an-animal.title'} />,
    content: <MeetAnAnimalContent />,
    category: 'usage',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'helpful-tips',
    route: 'helpful-tips',
    title: <FormattedMessage id={'pages.faqs.question.helpful-tips.title'} />,
    content: <HelpfulTipsContent />,
    category: 'adopted',
    backTo: <FormattedMessage id={'pages.faqs.page-title'} />,
  },
  {
    id: 'email',
    title: SUPPORT_EMAIL,
    href: `mailto:${SUPPORT_EMAIL}`,
    route: '',
    category: 'contact',
  },
  {
    id: 'phone',
    title: SUPPORT_PHONE_READABLE,
    href: `tel:${SUPPORT_PHONE_NUMERIC}`,
    route: '',
    category: 'contact',
  },
];

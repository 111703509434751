import React from 'react';

const DogPawBottomImage: React.FC<{}> = () => {
  return (
    <svg width="375" height="331" viewBox="0 0 375 331" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        opacity="0.3"
        cx="282.867"
        cy="210.629"
        r="56.877"
        transform="rotate(21.5701 282.867 210.629)"
        fill="white"
      />
      <path
        d="M256.194 182.422L254.954 190.476L253.714 198.529C253.58 199.393 253.598 200.242 253.684 201.077C237.346 339.049 205.334 483.097 162.492 631.354L217.226 658.425C261.332 498.735 295.933 344.673 315.066 199.772L316.307 191.718C317.158 186.2 313.339 180.987 307.82 180.135C302.371 179.294 297.238 183.015 296.293 188.423C297.019 182.979 293.245 177.881 287.796 177.04C282.346 176.199 277.214 179.92 276.268 185.328C276.995 179.883 273.22 174.786 267.771 173.945C262.253 173.088 257.043 176.906 256.194 182.422Z"
        fill="#4353CD"
      />
      <path
        d="M262.405 224.668C263.966 214.549 273.433 207.614 283.547 209.174C293.666 210.736 300.601 220.203 299.04 230.321C297.479 240.44 288.015 247.374 277.896 245.812C267.782 244.252 260.846 234.785 262.405 224.668Z"
        fill="#D9F9E4"
      />
      <path
        d="M301.308 190.15C301.797 186.981 304.757 184.808 307.926 185.297C311.095 185.786 313.268 188.751 312.779 191.92C312.29 195.089 309.327 197.26 306.155 196.773C302.989 196.282 300.819 193.319 301.308 190.15Z"
        fill="#D9F9E4"
      />
      <path
        d="M279.891 188.304C280.38 185.135 283.341 182.961 286.51 183.45C289.678 183.939 291.851 186.905 291.362 190.074C290.873 193.243 287.91 195.413 284.739 194.927C281.573 194.435 279.405 191.471 279.891 188.304Z"
        fill="#D9F9E4"
      />
      <path
        d="M258.879 185.062C259.368 181.893 262.329 179.719 265.498 180.208C268.666 180.697 270.839 183.663 270.35 186.832C269.861 190.001 266.898 192.171 263.727 191.684C260.561 191.193 258.393 188.228 258.879 185.062Z"
        fill="#D9F9E4"
      />
      <path d="M203.078 220.669C193.101 195.418 203.956 167.718 227.312 158.79" stroke="#232A47" />
      <path d="M184.57 213.6C174.592 188.349 185.447 160.648 208.803 151.721" stroke="#232A47" />
    </svg>
  );
};

export default DogPawBottomImage;

import React from 'react';

const LocationImage: React.FC = () => {
  return (
    <svg width="199" height="199" viewBox="0 0 199 199" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.2" cx="99.5" cy="99.5039" r="99" fill="url(#paint0_radial)" />
      <path
        opacity="0.2"
        d="M171.5 99.5039C171.5 139.268 139.265 171.504 99.5 171.504C59.7355 171.504 27.5 139.268 27.5 99.5039C27.5 59.7394 59.7355 27.5039 99.5 27.5039C139.265 27.5039 171.5 59.7394 171.5 99.5039ZM85.1 99.5039C85.1 107.457 91.5471 113.904 99.5 113.904C107.453 113.904 113.9 107.457 113.9 99.5039C113.9 91.551 107.453 85.1039 99.5 85.1039C91.5471 85.1039 85.1 91.551 85.1 99.5039Z"
        fill="url(#paint1_radial)"
      />
      <g filter="url(#filter0_d)">
        <circle cx="99.5" cy="99.5039" r="49" fill="#FFBA1D" />
        <circle cx="99.5" cy="99.5039" r="48.5" stroke="#313638" />
      </g>
      <g filter="url(#filter1_d)">
        <circle cx="99.5" cy="92.5039" r="49" fill="#FFDD59" />
        <circle cx="99.5" cy="92.5039" r="48.5" stroke="#313638" />
      </g>
      <path
        d="M120.413 84.0343C120.413 97.4107 100.47 119.504 100.47 119.504C100.47 119.504 80.5 97.4107 80.5 84.0343C80.6906 79.0578 82.8766 74.3464 86.5984 70.8906C90.3201 67.4348 95.2876 65.5039 100.457 65.5039C105.625 65.5039 110.593 67.4348 114.315 70.8906C118.036 74.3464 120.222 79.0578 120.413 84.0343Z"
        fill="white"
        stroke="#313638"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.847 92.8951C105.602 92.8951 109.456 89.0409 109.456 84.2864C109.456 79.532 105.602 75.6777 100.847 75.6777C96.093 75.6777 92.2388 79.532 92.2388 84.2864C92.2388 89.0409 96.093 92.8951 100.847 92.8951Z"
        fill="#FFDD59"
        stroke="#313638"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d"
          x="38.5"
          y="44.5039"
          width="122"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <filter
          id="filter1_d"
          x="38.5"
          y="37.5039"
          width="122"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="6" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0 0.141176 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(99.5 99.5039) rotate(90) scale(99)"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(99.5 99.5039) rotate(90) scale(72)"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default LocationImage;

import { USERNAME_CLEAR, USERNAME_STORE_LAST_USED, UsernameActionTypes } from './types';

export function usernameStoreLastUsed(username: string): UsernameActionTypes {
  return {
    type: USERNAME_STORE_LAST_USED,
    payload: username,
  };
}

export function usernameClearLastUsed(): UsernameActionTypes {
  return {
    type: USERNAME_CLEAR,
  };
}

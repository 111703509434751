import { ACTIVATION_SET_START_APPLICATION_ANIMAL_ID, ActivationState, ActivationTypes } from './types';

const initialState: ActivationState = {
  startApplicationAnimalId: undefined,
};

/**
 * @param state
 * @param action
 */
export function activationReducer(state = initialState, action: ActivationTypes): ActivationState {
  switch (action.type) {
    case ACTIVATION_SET_START_APPLICATION_ANIMAL_ID:
      return {
        ...state,
        startApplicationAnimalId: action.payload,
      };

    default:
      return state;
  }
}

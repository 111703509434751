import { AnimalIdType, Nil } from '@colensobbdo/shelter-management-frontend-integration';
import _indexOf from 'lodash/indexOf';
import _size from 'lodash/size';

import { RootState } from '../store';

export const getFavoriteAnimalIds = (state: RootState): AnimalIdType[] => state.favorite.animalIds;

export const getFavoriteAnimalIdsToDelete = (state: RootState): AnimalIdType[] => state.favorite.toDelete;

export const isFavoritedAnimal = (animalId: AnimalIdType | Nil) => (state: RootState) =>
  _indexOf(getFavoriteAnimalIds(state), animalId) !== -1;

export const getFavoriteCount = (state: RootState) => _size(getFavoriteAnimalIds(state));

import { Screen as ScreenLock } from '@colensobbdo/shelter-management-frontend';
import React, { Fragment, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

import Screen from '../../utilities/Screen';

type Props = {
  children?: React.ReactNode;
};

const ScrollToTop = (props: Props) => {
  const history = useHistory();

  useEffect(() => {
    const UN_LISTEN = history.listen(() => {
      ScreenLock.unlock();
      Screen.scrollToTop();
    });
    return () => {
      UN_LISTEN();
    };
  }, []);

  return <Fragment>{props.children}</Fragment>;
};

export default withRouter(ScrollToTop);

import { Container, Icons, Layout, LayoutContent, Row, Spinner } from '@colensobbdo/shelter-management-frontend';
import React from 'react';

const { LoadingIcon } = Icons;

type Props = {
  standalone?: boolean;
  centered?: boolean;
};

const LoadingCircle = () => (
  <Row justify="center" direction="horizontal">
    <Spinner>
      <LoadingIcon />
    </Spinner>
  </Row>
);

/**
 * @constructor
 */

const Loading: React.FC<Props> = ({ standalone, centered }) => {
  const _renderLoadingLayout = () => {
    if (standalone) return <LoadingCircle />;

    return (
      <Layout>
        <LayoutContent center>
          <Container>
            <LoadingCircle />
          </Container>
        </LayoutContent>
      </Layout>
    );
  };

  return centered ? <div className="flex-center h-full">{_renderLoadingLayout()}</div> : _renderLoadingLayout();
};

export default Loading;

import React from 'react';

const DogPawHumanHandBottomImage: React.FC<{}> = () => {
  return (
    <svg
      height="100%"
      viewBox="0 0 500 270"
      preserveAspectRatio="xMidYMid meet"
      className="dropDogPawHumanHandBottomImage"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>{`.dropDogPawHumanHandBottomImage { margin-bottom: -4px }`}</style>
      <path
        d="M129.4,137.5a10.84,10.84,0,0,1-10.3-7.2,11,11,0,0,1,6.5-14.1l.2-.1c7-2.6,17.4-5.3,27.8-3.2a11,11,0,0,1,8.5,13,10.9,10.9,0,0,1-10.7,8.7,17.85,17.85,0,0,1-2.3-.2c-3.9-.8-9.6,0-15.5,2.2a.76.76,0,0,1-.4.1A10.8,10.8,0,0,1,129.4,137.5Z"
        transform="translate(59.1 -0.9)"
        fill="#eba6b0"
      />
      <path
        d="M-26.5,359.2H-32a27.49,27.49,0,0,1-27.1-27.9,27.49,27.49,0,0,1,27.9-27.1c31.2.5,58.4-3.8,80.9-12.6,18.6-7.3,34.4-18,46.9-31.7,45.3-49.7,37.6-126.8,37.2-130.1v-.4a27.54,27.54,0,0,1,54.8-5.6h0a264.15,264.15,0,0,1-1.7,61.9,242.15,242.15,0,0,1-14.4,54.8,185.57,185.57,0,0,1-35,56.5c-18.2,20-40.9,35.4-67.4,45.9C42.2,353.7,9.7,359.2-26.5,359.2Z"
        transform="translate(59.1 -0.9)"
        fill="#eba6b0"
      />
      <path
        d="M156.8,136.9a9.82,9.82,0,0,1-9.8-9.1c-.9-10.8-6.8-24.7-12-32.9a9.86,9.86,0,1,1,16.7-10.5c6.1,9.8,13.7,26.6,14.9,41.8a9.85,9.85,0,0,1-9,10.6A2,2,0,0,1,156.8,136.9Z"
        transform="translate(59.1 -0.9)"
        fill="#eba6b0"
      />
      <path
        d="M143.4,186.4h-.5a10,10,0,0,1-9.4-10.3c1-21.1-.6-38.4-4.6-51.3a62,62,0,0,0-10.4-20l-.9-1.2a9.85,9.85,0,0,1,2.1-13.7A10,10,0,0,1,133.5,92c.3.4.5.7.8,1.1,7.5,10.1,21.4,28.9,18.9,83.9A9.8,9.8,0,0,1,143.4,186.4Z"
        transform="translate(59.1 -0.9)"
        fill="#eba6b0"
      />
      <path
        d="M179,149.5h-.5a10,10,0,0,1-9.4-10.3c1-22.3-4-32.1-14.9-47.7a9.83,9.83,0,1,1,16.1-11.3c6.4,9.1,11.4,17.2,14.5,26.3,3.4,9.7,4.6,20.1,4,33.6A9.87,9.87,0,0,1,179,149.5Z"
        transform="translate(59.1 -0.9)"
        fill="#eba6b0"
      />
      <path
        d="M161.1,123.6c7.6-3,12.1-9.4,10.2-14.3s-9.6-6.6-17.2-3.6S142,115.1,143.9,120,153.5,126.6,161.1,123.6Z"
        transform="translate(59.1 -0.9)"
        fill="#fffae0"
      />
      <path
        d="M168,95.7c2.4-1,3.3-4.5,2-7.8s-4.4-5.1-6.9-4.1-3.3,4.5-2,7.8S165.5,96.7,168,95.7Z"
        transform="translate(59.1 -0.9)"
        fill="#fffae0"
      />
      <path
        d="M148.9,100.9c2.7-1.1,3.8-4.9,2.3-8.5s-4.8-5.7-7.5-4.6-3.8,4.9-2.3,8.5S146.1,101.9,148.9,100.9Z"
        transform="translate(59.1 -0.9)"
        fill="#fffae0"
      />
      <path
        d="M131.4,107.9c2.5-1.5,3-5.5,1-8.9s-5.7-5.1-8.2-3.6-3,5.5-1,8.9S128.9,109.4,131.4,107.9Z"
        transform="translate(59.1 -0.9)"
        fill="#fffae0"
      />
      <path
        d="M405.4,304.2c-57.7.9-101.4-14.5-130-45.7-21.7-23.8-31.7-53.6-36.3-77.3-3.7-32.1,4.6-75.4,4.7-76a10.24,10.24,0,1,0-20.1-3.9,366,366,0,0,0-5.2,40.3c-.9-22.5,1.5-43.4,2.8-54.8.2-1.8.4-3.4.5-4.6a10.2,10.2,0,1,0-20.3-2.1c-.1,1.2-.3,2.7-.5,4.4-1.5,12.8-4.3,32.5-2.7,58.5-3.4-28.3-3.9-51.9-3.9-52.4a10.31,10.31,0,0,0-10.3-10.1H184a10.27,10.27,0,0,0-10.1,10.4c0,1.8.5,27.7,3.7,55.6a39.49,39.49,0,0,1-3.6-5,10.19,10.19,0,0,0-17.7,10.1c.4.8,9.4,16.2,25.8,24,.3,1.7.7,3.4,1.1,5.1,4.9,32.4,17.8,77.9,51.5,114.8,38.6,42.3,94.4,63.7,165.9,63.7h5.6a27.5,27.5,0,1,0-.8-55Z"
        transform="translate(59.1 -0.9)"
        fill="#4353cd"
      />
      <path
        d="M400.7,360C329,360,273,338.5,234.2,296c-34-37.3-46.9-83.2-51.7-115.2-.3-1.5-.7-3.1-1-4.7-16.4-8-25.4-23.5-25.8-24.2a11,11,0,0,1,19-11,20.8,20.8,0,0,0,1.9,2.8c-2.9-27.1-3.4-51.9-3.4-53A11,11,0,0,1,184,79.5h.2a11,11,0,0,1,11,10.8c0,.2.3,14.1,2,33.2a341.22,341.22,0,0,1,2.7-35.9c.2-1.2.3-2.3.4-3.4V84c.2-1.7.3-3.1.5-4.3a11,11,0,0,1,12.1-9.8A11.08,11.08,0,0,1,222.7,82c-.1,1.3-.3,2.7-.5,4.7-1,8.8-2.7,23.5-3,40.3,1.6-14.3,3.6-24.6,3.8-26.1a11,11,0,0,1,4.6-7,11.34,11.34,0,0,1,8.2-1.7,11.1,11.1,0,0,1,8.7,12.9c-.1.4-8.4,43.7-4.7,75.7a185.09,185.09,0,0,0,11.6,38.4,139,139,0,0,0,24.4,38.5c28.4,31.1,71.9,46.4,129.4,45.5a28.34,28.34,0,0,1,20.1,7.9,27.79,27.79,0,0,1,8.6,19.9,28.26,28.26,0,0,1-27.8,28.7A32,32,0,0,1,400.7,360ZM165.3,137.1a10,10,0,0,0-4.7,1.2,9.48,9.48,0,0,0-3.5,12.9c.4.7,9.3,16,25.4,23.7a.66.66,0,0,1,.4.5c.3,1.7.7,3.5,1.1,5.1v.1c4.8,31.8,17.5,77.4,51.3,114.4,38.4,42.1,94.1,63.5,165.4,63.5h5.5a26.8,26.8,0,0,0,26.4-27.2,26.8,26.8,0,0,0-27.2-26.4c-58,.9-101.9-14.6-130.5-46A137.45,137.45,0,0,1,250.2,220a184.73,184.73,0,0,1-11.7-38.8v-.1c-3.7-32.2,4.6-75.8,4.7-76.2a9.52,9.52,0,0,0-7.5-11.1,9,9,0,0,0-7.1,1.5,9.5,9.5,0,0,0-4,6.1,372,372,0,0,0-5.2,40.2.77.77,0,0,1-.8.7.85.85,0,0,1-.8-.7c-.9-22.6,1.5-43.6,2.8-54.9.2-1.9.4-3.4.5-4.6a9.45,9.45,0,1,0-18.8-2c-.1,1.2-.3,2.6-.5,4.3v.2c-.1,1.1-.3,2.2-.4,3.4-1.5,12.4-3.8,31.2-2.3,54.9a.75.75,0,1,1-1.5.1c-3.4-28.7-3.9-52.2-3.9-52.5a9.48,9.48,0,0,0-9.6-9.3H184a9.48,9.48,0,0,0-9.3,9.6c0,1.1.5,27.5,3.7,55.5a1,1,0,0,1-.4.8,1,1,0,0,1-.9-.2,56.23,56.23,0,0,1-3.7-5.1A9.12,9.12,0,0,0,165.3,137.1Z"
        transform="translate(59.1 -0.9)"
        fill="#121ba5"
      />
      <path
        d="M162.8,53.3a.84.84,0,0,1-.7-.4l-16.8-35a.74.74,0,0,1,1.3-.7l16.8,35a.78.78,0,0,1-.3,1A.37.37,0,0,1,162.8,53.3Z"
        transform="translate(59.1 -0.9)"
        fill="#232a47"
      />
      <path
        d="M183.2,53.3a.37.37,0,0,1-.3-.1.69.69,0,0,1-.3-1l16.8-35a.74.74,0,0,1,1.3.7l-16.8,35A.84.84,0,0,1,183.2,53.3Z"
        transform="translate(59.1 -0.9)"
        fill="#232a47"
      />
      <path
        d="M172.7,53a.79.79,0,0,1-.8-.8V1.7a.79.79,0,0,1,.8-.8.74.74,0,0,1,.8.8V52.3A.77.77,0,0,1,172.7,53Z"
        transform="translate(59.1 -0.9)"
        fill="#232a47"
      />
    </svg>
  );
};

export default DogPawHumanHandBottomImage;

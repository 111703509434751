import './styles';

import { Icons, Tag } from '@colensobbdo/shelter-management-frontend';
import routes from 'configs/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFavoriteCount } from 'store/favorites/selectors';

const { HeartFilledIcon } = Icons;

/**
 * @constructor
 */
const FavoriteCountButton: React.FC<{}> = () => {
  const history = useHistory();

  const COUNT = useSelector(getFavoriteCount);

  return (
    <Tag
      className="favourites-tag"
      affix={<HeartFilledIcon />}
      onClick={() => history.push(routes.pages.animals.favorites)}
    >
      {COUNT}
    </Tag>
  );
};

export default FavoriteCountButton;

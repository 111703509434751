import { AnimalIdType, Nil } from '@colensobbdo/shelter-management-frontend-integration';

// types

export type FavoriteActionPayload = AnimalIdType | Nil;

// state

export type FavoriteState = {
  animalIds: AnimalIdType[];
  toDelete: AnimalIdType[];
};

// action

export const FAVORITE_ADD_ANIMAL = 'FAVORITE_ADD_ANIMAL';
export const FAVORITE_REMOVE_ANIMAL = 'FAVORITE_REMOVE_ANIMAL';
export const FAVORITE_TOGGLE_ANIMAL = 'FAVORITE_TOGGLE_ANIMAL';
export const FAVORITE_CLEAR = 'FAVORITE_CLEAR';
export const FAVORITE_REPLACE = 'FAVORITE_REPLACE';

interface FavoriteAddAnimalAction {
  type: typeof FAVORITE_ADD_ANIMAL;
  payload: AnimalIdType;
}

interface FavoriteRemoveAnimalAction {
  type: typeof FAVORITE_REMOVE_ANIMAL;
  payload: AnimalIdType;
}

interface FavoriteToggleAnimalAction {
  type: typeof FAVORITE_TOGGLE_ANIMAL;
  payload: FavoriteActionPayload;
}

interface FavoriteClearAction {
  type: typeof FAVORITE_CLEAR;
}

interface FavoriteReplaceAction {
  type: typeof FAVORITE_REPLACE;
  payload: FavoriteActionPayload[];
}

export type FavoriteActionTypes =
  | FavoriteAddAnimalAction
  | FavoriteRemoveAnimalAction
  | FavoriteToggleAnimalAction
  | FavoriteClearAction
  | FavoriteReplaceAction;

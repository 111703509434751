import { PERSON_DATA_CLEAR, PERSON_DATA_UPDATE, PersonActionTypes, PersonDataUpdatePayload } from './types';

export function personDataUpdate(data: PersonDataUpdatePayload): PersonActionTypes {
  return {
    payload: data,
    type: PERSON_DATA_UPDATE,
  };
}

export function personDataClear(): PersonActionTypes {
  return {
    type: PERSON_DATA_CLEAR,
  };
}

export function personSetActive(): PersonActionTypes {
  return {
    payload: {
      isActive: true,
    },
    type: PERSON_DATA_UPDATE,
  };
}

import { AnimalSpecieCategoryId } from '@colensobbdo/shelter-management-frontend-integration';
import loadable from '@loadable/component';
import PrivateRoute from 'components/PrivateRoute';
import { OnboardingRouteProps } from 'pages/OnboardingPage/OnboardingPage';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import { pushGtmEvent } from 'services/GTM';
import { isAuthenticated } from 'store/auth/selectors';
import { getPersonId } from 'store/person/selectors';

import RouteSwitch from './components/RouteSwitch';
import routes from './configs/routes';
import { Faqs } from './pages/FaqPage/modules/Faqs';

const AccountSettingsPage = loadable(() => import('./pages/AccountSettingsPage'));
const AnimalForcedApplicationPage = loadable(() => import('./pages/AnimalForcedApplicationPage'));
const ApplicationRedirectPage = loadable(() => import('./pages/ApplicationRedirectPage'));
const AnimalProfilePage = loadable(() => import('./pages/AnimalProfilePage'));
const LandingPage = loadable(() => import('./pages/LandingPage'));
const RegistrationConfirmPage = loadable(() => import('./pages/RegistrationConfirmPage'));
const FaqPage = loadable(() => import('./pages/FaqPage'));
const ForgotPasswordPage = loadable(() => import('./pages/ForgotPasswordPage'));
const LoginPage = loadable(() => import('./pages/LoginPage'));
const LogoutPage = loadable(() => import('./pages/LogoutPage'));
const MatchesPage = loadable(() => import('./pages/MatchesPage'));
const NoMatchPage = loadable(() => import('./pages/NoMatchPage'));
const NotificationsAndPrivacySettingsPage = loadable(() => import('./pages/NotificationsAndPrivacySettingsPage'));
const OnboardingPage = loadable(() => import('./pages/OnboardingPage'));
const PrivacyPolicyPage = loadable(() => import('./pages/PrivacyPolicyPage'));
const RegistrationPage = loadable(() => import('./pages/RegistrationPage'));
const ResetPasswordPage = loadable(() => import('./pages/ResetPasswordPage'));
const ProfileSettingsPage = loadable(() => import('./pages/ProfileSettingsPage'));
const TermsAndConditionsPage = loadable(() => import('./pages/TermsAndConditionsPage'));
const ResendActivationEmailPage = loadable(() => import('./pages/ResendActivationEmailPage'));
const FavoritesPage = loadable(() => import('./pages/FavoritesPage'));
const UnsubscribePage = loadable(() => import('./pages/UnsubscribePage'));
const PromotionCouponPage = loadable(() => import('./pages/PromotionCouponPage'));

const Routes = () => {
  const IS_AUTHENTICATED = useSelector(isAuthenticated);
  const PERSON_ID = useSelector(getPersonId);

  useEffect(() => {
    if (!IS_AUTHENTICATED || !PERSON_ID) return;

    pushGtmEvent({ event: 'USER', PersonID: PERSON_ID });
  }, [IS_AUTHENTICATED, PERSON_ID]);

  const faqRoutes = Faqs.map((faq) => (
    <Route key={faq.id} exact path={`${routes.pages.faqPage}/${faq.route}`}>
      <FaqPage id={faq.id} />
    </Route>
  ));

  return (
    <Router>
      <RouteSwitch>
        <Switch>
          <PrivateRoute exact path={routes.pages.settings.account} component={AccountSettingsPage} />
          <PrivateRoute path={routes.pages.animals.application.forced} component={AnimalForcedApplicationPage} />
          <PrivateRoute exact path={routes.pages.settings.profile} component={ProfileSettingsPage} />
          <PrivateRoute
            exact
            path={routes.pages.settings.notificationsAndPrivacy}
            component={NotificationsAndPrivacySettingsPage}
          />
          <Route exact path={routes.pages.animals.application.redirect}>
            <ApplicationRedirectPage />
          </Route>
          <Route path={routes.pages.animals.profile} component={AnimalProfilePage} />
          <Route path={routes.pages.matches} component={MatchesPage} />
          <Route
            path={routes.pages.onboarding}
            component={(props: RouteComponentProps) => {
              const componentState = props?.location?.state as OnboardingRouteProps;
              return <OnboardingPage {...componentState} />;
            }}
          />
          <Route exact path={routes.pages.forgotPassword}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={routes.pages.login}>
            <LoginPage />
          </Route>
          <Route exact path={routes.pages.logout}>
            <LogoutPage />
          </Route>
          <Route exact path={routes.pages.registration.root}>
            <RegistrationPage />
          </Route>
          <Route exact path={routes.pages.resetPassword}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={routes.pages.registration.confirm}>
            <RegistrationConfirmPage />
          </Route>
          <Route exact path={routes.pages.privacyPolicy}>
            <PrivacyPolicyPage />
          </Route>
          <Route exact path={routes.pages.termsAndConditionsPage}>
            <TermsAndConditionsPage />
          </Route>
          <Route exact path={routes.pages.resendActivationEmail} component={ResendActivationEmailPage} />
          <Route exact path={routes.pages.animals.favorites}>
            <FavoritesPage />
          </Route>
          <Route exact path={routes.pages.unsubscribe} component={UnsubscribePage} />
          {faqRoutes}
          <Route exact path={routes.pages.promotionCoupon}>
            <PromotionCouponPage />
          </Route>
          <Route exact path={routes.pages.root} component={LandingPage} />
          <Route component={NoMatchPage} />
        </Switch>
      </RouteSwitch>
    </Router>
  );
};

export default Routes;

import { ActionLayer, Button, Heading, Icons, Message, Spacer, Text } from '@colensobbdo/shelter-management-frontend';
import { ICoordinates } from '@colensobbdo/shelter-management-frontend-integration';
import AddressAutocompleteField from 'components/AutoAddressField';
import Loading from 'components/Loading';
import CONFIG from 'configs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Images from '../Images';

const { LocationImage } = Images;
const { SearchIcon } = Icons;

type Props = {
  open?: boolean;
  handleClose?: () => void;
  handleAction?: (location: ICoordinates) => void;
};

const ClearIndicator = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} data-indicator="clear" style={{ marginRight: '1rem' }}>
      <Text color="primary-500" tag="small">
        <FormattedMessage id={'common.action-layer.location.search.clear.text'} />
      </Text>
    </div>
  );
};

const LocationActionLayer: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { open, handleClose, handleAction } = props;
  const [error, setError] = useState<string | undefined>();
  const [location, setLocation] = useState<ICoordinates | null>(null);
  const [waiting, setWaiting] = useState(false);
  const [showAddressFinder, setShowAddressFinder] = useState(false);

  const doConfirm = (position: ICoordinates | null) => {
    if (position && handleAction) {
      handleAction(position);
      handleClose && handleClose();
    }
  };

  const _runGeolocation = () => {
    if (!('geolocation' in navigator)) {
      setError(intl.formatMessage({ id: 'common.action-layer.location.error.generic' }));
      return;
    }

    setWaiting(true);

    navigator.geolocation.getCurrentPosition(
      function (position) {
        if (position.coords) doConfirm(position.coords);
        else setError(intl.formatMessage({ id: 'common.action-layer.location.error.generic' }));
        setWaiting(false);
      },
      function (error) {
        setError(
          error.code === error.PERMISSION_DENIED
            ? intl.formatMessage({ id: 'common.action-layer.location.error.location-permission' })
            : intl.formatMessage({ id: 'common.action-layer.location.error.generic' }),
        );
        setWaiting(false);
      },
    );
  };

  useEffect(() => {
    if (!open) setShowAddressFinder(false);
  }, [open]);

  return (
    <ActionLayer
      open={open}
      backdrop={true}
      suffix={true}
      onClick={handleClose}
      title={
        <Heading level="h4">
          <FormattedMessage id={'common.action-layer.location.title'} />
        </Heading>
      }
    >
      <Spacer size="small">
        <Heading level="h7">
          <FormattedMessage
            id={showAddressFinder ? 'common.action-layer.location.search.text' : 'common.action-layer.location.text'}
          />
        </Heading>
        {showAddressFinder ? (
          <Spacer size="small">
            <AddressAutocompleteField
              placeholder={intl.formatMessage({ id: 'common.action-layer.location.search.placeholder' })}
              onSelectAddress={setLocation}
              apiKey={CONFIG.googleMapsPlacesApiKey}
              countryCode="NZ" // TODO: we don't know the country of the user here!
              bordered
              ClearIndicator={ClearIndicator}
              affix={SearchIcon}
              className="u-mb--xxxl"
              focusOnRender
            />
            <Button disabled={!location} block color="primary" onClick={() => doConfirm(location)}>
              <FormattedMessage id="common.action-layer.location.button.confirm" />
            </Button>
            <Button
              bordered
              block
              color="primary"
              onClick={() => {
                setShowAddressFinder(false);
                setLocation(null);
              }}
            >
              <FormattedMessage id="common.action-layer.location.button.cancel" />
            </Button>
          </Spacer>
        ) : (
          <Spacer size="small">
            <Text align="center">
              <LocationImage />
            </Text>
            <Button block color="primary" onClick={_runGeolocation} variant={!!error ? 'error' : undefined}>
              {waiting ? (
                <Loading standalone centered />
              ) : (
                <FormattedMessage id="common.action-layer.location.button.auto" />
              )}
            </Button>
            <Button
              bordered
              block
              color="primary"
              onClick={() => {
                setError(undefined);
                setShowAddressFinder(true);
              }}
            >
              <FormattedMessage id="common.action-layer.location.button.search" />
            </Button>
          </Spacer>
        )}
        {error && <Message type="error">{error}</Message>}
      </Spacer>
    </ActionLayer>
  );
};

export default LocationActionLayer;

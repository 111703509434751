import { Notification } from '@colensobbdo/shelter-management-frontend';
import React, { createContext, useState } from 'react';

interface INotification {
  type: 'success' | 'error' | 'standard';
  message: string;
}

interface NotificationContextProps {
  addNotification: (_notification: INotification) => void;
}

interface NotificationProviderProps {
  children?: React.ReactNode;
}

const notifications: INotification[] = [];
let showing = false;

const wait = (ms: number) => new Promise<void>((resolve, reject) => setTimeout(() => resolve(), ms));

export const NotificationContext = createContext({} as NotificationContextProps);

export const NotificationProvider = (props: NotificationProviderProps) => {
  const [notification, setNotification] = useState<INotification>();
  const [active, setActive] = useState(false);

  const addNotification = (_notification: INotification) => {
    notifications.push(_notification);
    if (!showing) showNotification();
  };

  const showNotification = async () => {
    showing = true;

    if (notifications.length === 0) {
      setNotification(undefined);
      showing = false;
      return;
    }

    setNotification(notifications.shift());

    setActive(true);
    await wait(3000);

    setActive(false);
    await wait(500);

    showNotification();
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      <Notification active={active} type={notification?.type}>
        <strong>{notification?.message}</strong>
      </Notification>
      {props.children}
    </NotificationContext.Provider>
  );
};

import './scss/index.scss';

import { NotificationProvider } from 'components/NotificationProvider';
import CONFIG from 'configs';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import MESSAGES from './i18n/en.json';
import Routes from './routes';
import { persistor, store } from './store/store';

const App: React.FC = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={CONFIG.locale} messages={MESSAGES} timeZone={CONFIG.timezone}>
          <NotificationProvider>
            <Routes />
          </NotificationProvider>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

export default App;

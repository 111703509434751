import _compact from 'lodash/compact';
import { Dispatch } from 'redux';

import { thunkSyncFavorites } from './thunks';
import {
  FAVORITE_ADD_ANIMAL,
  FAVORITE_CLEAR,
  FAVORITE_REMOVE_ANIMAL,
  FAVORITE_REPLACE,
  FAVORITE_TOGGLE_ANIMAL,
  FavoriteActionPayload,
  FavoriteActionTypes,
} from './types';

export function favoriteAddAnimal(data: FavoriteActionPayload) {
  return (dispatch: Dispatch) => {
    if (!data) {
      return;
    }

    dispatch({
      type: FAVORITE_ADD_ANIMAL,
      payload: data,
    });

    dispatch<any>(thunkSyncFavorites());
  };
}

export function favoriteRemoveAnimal(data: FavoriteActionPayload) {
  return (dispatch: Dispatch) => {
    if (!data) {
      return;
    }

    dispatch({
      type: FAVORITE_REMOVE_ANIMAL,
      payload: data,
    });

    dispatch<any>(thunkSyncFavorites());
  };
}

export function favoriteToggleAnimal(data: FavoriteActionPayload) {
  return (dispatch: Dispatch) => {
    if (!data) {
      return;
    }

    dispatch({
      type: FAVORITE_TOGGLE_ANIMAL,
      payload: data,
    });

    dispatch<any>(thunkSyncFavorites());
  };
}

export function favoriteDataClear(): FavoriteActionTypes {
  return {
    type: FAVORITE_CLEAR,
  };
}

export function favoriteDataReplace(data: FavoriteActionPayload[]): FavoriteActionTypes {
  return {
    type: FAVORITE_REPLACE,
    payload: _compact(data),
  };
}

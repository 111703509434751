import { USERNAME_CLEAR, USERNAME_STORE_LAST_USED, UsernameActionTypes, UsernameState } from './types';

const initialState: UsernameState = {
  lastUsername: undefined,
};

/**
 * @param state
 * @param action
 */
export function usernameReducer(state = initialState, action: UsernameActionTypes): UsernameState {
  switch (action.type) {
    case USERNAME_STORE_LAST_USED:
      return {
        ...state,
        lastUsername: action.payload,
      };
    case USERNAME_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import { SHELTER_DATA_CLEAR, SHELTER_DATA_UPDATE, ShelterActionTypes, ShelterState } from './types';

const initialState: ShelterState = {
  shelterId: null,
  name: null,
  photoUrl: null,
};

/**
 * @param state
 * @param action
 */
export function shelterReducer(state = initialState, action: ShelterActionTypes): ShelterState {
  switch (action.type) {
    case SHELTER_DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case SHELTER_DATA_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import jwtDecode from 'jwt-decode';

const Jwt = {
  /**
   * Decode a JWT token
   * @param token
   */
  decode: (token: string) => jwtDecode(token),
};

export default Jwt;

export default {
  pages: {
    animals: {
      profile: '/animals/profile/:animalId',
      application: {
        forced: '/animals/applications/new/:applicationId',
        redirect: '/animals/application/:applicationId',
      },
      favorites: '/animals/favorites',
    },
    settings: {
      account: '/settings/account',
      profile: '/settings/profile',
      notificationsAndPrivacy: '/settings/notifications-and-privacy',
    },
    onboarding: '/onboarding',
    matches: '/matches',
    forgotPassword: '/forgot-password',
    login: '/login',
    logout: '/logout',
    registration: {
      root: '/registration',
      confirm: '/registration/confirm',
      acceptInvite: '/register/validate-email',
    },
    resendActivationEmail: '/resend-activation-email',
    resetPassword: '/reset-password',
    privacyPolicy: '/privacy-policy',
    termsAndConditionsPage: '/terms-and-conditions',
    unsubscribe: '/unsubscribe',
    faqPage: '/faq',
    promotionCoupon: '/gt10i4ist9rbjkg0',
    root: '/',
  },
  external: {
    shelter: {
      root: 'https://shelter.myhooman.com',
      faq: 'https://shelter.myhooman.com/faqs',
    },
  },
};

import { AnimalSpecieCategoryId } from '@colensobbdo/shelter-management-frontend-integration';

import {
  SEARCH_CLEAR_DATA,
  SEARCH_CLEAR_SPECIES_DATA,
  SEARCH_STORE_ANIMAL_CATEGORY_ID,
  SEARCH_STORE_NEW_COORDINATE,
  SEARCH_STORE_PARAMETERS,
  SEARCH_STORE_REPLACE_ATTRIBUTES,
  SearchActionTypes,
  SearchStoreNewCoordinateActionPayload,
  SearchStoreParametersActionPayload,
  SpeciesAdopterAttribute,
} from './types';

export function searchStoreNewCoordinate(coordinates: SearchStoreNewCoordinateActionPayload): SearchActionTypes {
  return {
    type: SEARCH_STORE_NEW_COORDINATE,
    payload: coordinates,
  };
}

export function searchStoreReplaceAttributes(attributes: SpeciesAdopterAttribute): SearchActionTypes {
  return {
    type: SEARCH_STORE_REPLACE_ATTRIBUTES,
    payload: attributes,
  };
}

export function searchStoreParameters(parameters: SearchStoreParametersActionPayload): SearchActionTypes {
  return {
    type: SEARCH_STORE_PARAMETERS,
    payload: parameters,
  };
}

export function searchClearSpeciesData(animalCategoryId: AnimalSpecieCategoryId): SearchActionTypes {
  return {
    type: SEARCH_CLEAR_SPECIES_DATA,
    payload: animalCategoryId,
  };
}

export function searchClearData(): SearchActionTypes {
  return {
    type: SEARCH_CLEAR_DATA,
  };
}

export function searchStoreAnimalCategoryId(animalCategoryId: AnimalSpecieCategoryId): SearchActionTypes {
  return {
    type: SEARCH_STORE_ANIMAL_CATEGORY_ID,
    payload: animalCategoryId,
  };
}

import {
  AccessLevelType,
  Distance,
  IAuthTokens,
  ensureInt,
  makeLoggers,
} from '@colensobbdo/shelter-management-frontend-integration';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import Jwt from '../../utilities/Jwt';
import { logout } from '../logout/actions';
import { personDataUpdate } from '../person/actions';
import { PersonState } from '../person/types';
import { shelterDataUpdate } from '../shelter/actions';
import { ShelterState } from '../shelter/types';
import { RootState } from '../store';
import { authDataUpdate } from './actions';
import { getAccessToken, getRefreshToken } from './selectors';
import { AuthState } from './types';

const { fork } = makeLoggers('state :: thunk :: auth');

/*
 * This will decode the authToken payload and extract
 * some user/shelter information to store within redux state.
 *
 * Sample JWT Payload from createUser API
  {
    "Permission": [
      "PersonalSettingsUpdate",
      "PersonalSettingsView",
      "ShelterSettingsUpdate",
      "ShelterSettingsView"
    ],
    "CountryID": "1",
    "PersonID": "4",
    "Email": "fabio.cigliano@colensobbdo.co.nz",
    "nbf": 1594269093,
    "exp": 1594269393,
    "iat": 1594269093,
    "iss": "https://ped-dev-web-api.azurewebsites.net/",
    "aud": "https://ped-dev-web-api.azurewebsites.net/"
  }
  *
  * Sample JWT Payload from createShelter API
  {
    "Permission": [
      "PersonalSettingsUpdate",
      "PersonalSettingsView",
      "ShelterSettingsUpdate",
      "ShelterSettingsView"
    ],
    "PersonID": "4",
    "ShelterID": "4",
    "ShelterPersonID": "4",
    "Email": "fabio.cigliano@colensobbdo.co.nz",
    "nbf": 1594269110,
    "exp": 1594269410,
    "iat": 1594269110,
    "iss": "https://ped-dev-web-api.azurewebsites.net/",
    "aud": "https://ped-dev-web-api.azurewebsites.net/"
  }
  *
  * Sample JWT payload from an active user
  {
    "Permission": [
      "AppAccessApplication",
      "CommunityProfileUnarchive",
      "CommunityApplicationView",
      "CommunityApplicationUpdate",
      "CommunityApplicationArchive",
      "CommunityApplicationUnarchive",
      "CommunityApplicationChangeStatus",
      "CommunityProfileArchive",
      "TaskCreate",
      "TaskUpdateCommunity",
      "TaskDeleteCommunity",
      "ShelterSettingsView",
      "ShelterSettingsUpdate",
      "TaskView",
      "PersonalSettingsView",
      "CommunityProfileDelete",
      "CommunityProfileView",
      "AppChangePermissions",
      "AppViewArchive",
      "DogProfileView",
      "DogProfileUpdate",
      "DogProfileDelete",
      "DogProfilePublish",
      "CommunityProfileUpdate",
      "DogProfileArchive",
      "DogProfileTransfer",
      "DogApplicationView",
      "DogApplicationUpdate",
      "DogApplicationArchive",
      "DogApplicationUnarchive",
      "DogApplicationChangeStatus",
      "DogProfileUnarchive",
      "PersonalSettingsUpdate"
    ],
    "AccessLevel": "1",
    "CountryID": "1",
    "PersonID": "26",
    "ShelterID": "9",
    "ShelterPersonID": "16",
    "nameid": "4f0df6f6-7938-4d50-b100-a4fa7b783103",
    "nbf": 1597022332,
    "exp": 1597022632,
    "iat": 1597022332,
    "iss": "https://ped-dev-web-api.azurewebsites.net/",
    "aud": "https://ped-dev-web-api.azurewebsites.net/"
  }
 */
export const thunkSaveTokens =
  (tokens: IAuthTokens | null): ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const { log, logi } = fork('saveTokens');

    log('start', { tokens });

    if (!tokens) {
      dispatch(logout());

      return;
    }

    const decoded = Jwt.decode(tokens.accessToken);
    log('authToken payload', decoded);

    const newCredentials: Partial<AuthState> = {
      ...tokens,
      scope: _get(decoded, 'Permission', []),
    };

    const newUser: Partial<PersonState> = {};
    const newShelter: Partial<ShelterState> = {};

    let accessLevel = _get(decoded, 'AccessLevel');
    if (accessLevel) {
      accessLevel = ensureInt(accessLevel);

      log('storing accessLevel', accessLevel);
      newCredentials.accessLevel = accessLevel as AccessLevelType;
    }

    let countryId = _get(decoded, 'CountryID');
    if (countryId) {
      countryId = ensureInt(countryId);

      log('storing countryId', countryId);
      newUser.countryId = countryId;
    }

    let personId = _get(decoded, 'PersonID');
    if (personId) {
      personId = ensureInt(personId);

      log('storing personId', personId);
      newUser.personId = personId;
    }

    let shelterPersonId = _get(decoded, 'ShelterPersonID');
    if (shelterPersonId) {
      shelterPersonId = ensureInt(shelterPersonId);

      log('storing shelterPersonId', shelterPersonId);
      newUser.shelterPersonId = shelterPersonId;
    }

    let shelterId = _get(decoded, 'ShelterID');
    if (shelterId) {
      shelterId = ensureInt(shelterId);

      log('storing shelterId', shelterId);
      newShelter.shelterId = shelterId;
    }

    let email = _get(decoded, 'Email');
    if (email) {
      log('storing email', email);

      newUser.email = email;
    }

    log('storing new credentials', newCredentials);
    dispatch(authDataUpdate(newCredentials));

    if (!_isEmpty(newUser)) {
      logi('storing new user details', newUser);
      dispatch(personDataUpdate(newUser));
    }

    if (!_isEmpty(newShelter)) {
      logi('storing new shelter details', newShelter);
      dispatch(shelterDataUpdate(newShelter));
    }
  };

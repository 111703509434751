export type PreferencesState = {
  didShowRegisterActionLayer: boolean;
  didShowFavouriteActionLayer: boolean;
  didShowGUOHModal: boolean;
};

export const PREFERENCE_REGISTER_ACTION_LAYER_SHOWN = 'PREFERENCE_REGISTER_ACTION_LAYER_SHOWN';
export const PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN = 'PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN';
export const PREFERENCE_GUOH_MODAL_SHOWN = 'PREFERENCE_GUOH_MODAL_SHOWN';
export const PREFERENCE_CLEAR = 'PREFERENCE_CLEAR';

interface PreferenceRegisterActionLayerShownAction {
  type: typeof PREFERENCE_REGISTER_ACTION_LAYER_SHOWN;
}

interface PreferenceFavouriteActionLayerShownAction {
  type: typeof PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN;
}

interface PreferenceGUOHModalShownAction {
  type: typeof PREFERENCE_GUOH_MODAL_SHOWN;
}

interface PreferenceClearAction {
  type: typeof PREFERENCE_CLEAR;
}

export type PreferenceActionTypes =
  | PreferenceRegisterActionLayerShownAction
  | PreferenceFavouriteActionLayerShownAction
  | PreferenceGUOHModalShownAction
  | PreferenceClearAction;

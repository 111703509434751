const Screen = {
  /**
   * Scroll to the top of the screen.
   * @constructor
   */
  scrollToTop: () => {
    window.scrollTo(0, 0);
  },
};

export default Screen;

import { AdopterAttributeIdType, Nil } from '@colensobbdo/shelter-management-frontend-integration';
import { AnimalSpecieCategoryId } from '@colensobbdo/shelter-management-frontend-integration';

export type AttributeMap = Partial<Record<AnimalSpecieCategoryId, number[]>>;

export type SearchState = {
  attributeMap: AttributeMap | Nil;
  animalSpeciesCategory: AnimalSpecieCategoryId | Nil;
  latitude: number | Nil;
  longitude: number | Nil;
};

export const SEARCH_STORE_NEW_COORDINATE = 'SEARCH_STORE_NEW_COORDINATE';
export const SEARCH_STORE_NEW_ATTRIBUTE = 'SEARCH_STORE_NEW_ATTRIBUTE';
export const SEARCH_STORE_REPLACE_ATTRIBUTES = 'SEARCH_STORE_REPLACE_ATTRIBUTES';
export const SEARCH_STORE_PARAMETERS = 'SEARCH_STORE_PARAMETERS';
export const SEARCH_CLEAR_SPECIES_DATA = 'SEARCH_CLEAR_SPECIES_DATA';
export const SEARCH_CLEAR_DATA = 'SEARCH_CLEAR_DATA';
export const SEARCH_STORE_ANIMAL_CATEGORY_ID = 'SEARCH_STORE_ANIMAL_CATEGORY_ID';

export type SearchStoreParametersActionPayload = {
  attributes: SpeciesAdopterAttribute;
  animalSpeciesCategory: AnimalSpecieCategoryId | Nil;
  latitude: number;
  longitude: number;
};

export type SearchStoreNewCoordinateActionPayload = {
  latitude: number | null;
  longitude: number | null;
};

interface SearchStoreNewCoordinateAction {
  type: typeof SEARCH_STORE_NEW_COORDINATE;
  payload: SearchStoreNewCoordinateActionPayload;
}

export interface SpeciesAdopterAttribute {
  animalSpeciesCategory: AnimalSpecieCategoryId;
  attributes: number[];
}

export type SearchStoreNewAttributeActionPayload = AdopterAttributeIdType | AdopterAttributeIdType[];

interface SearchStoreNewAttributeAction {
  type: typeof SEARCH_STORE_NEW_ATTRIBUTE;
  payload: SearchStoreNewAttributeActionPayload;
}

interface SearchStoreParametersAction {
  type: typeof SEARCH_STORE_PARAMETERS;
  payload: SearchStoreParametersActionPayload;
}

interface SearchClearSpeciesDataAction {
  type: typeof SEARCH_CLEAR_SPECIES_DATA;
  payload: AnimalSpecieCategoryId;
}

interface SearchClearDataAction {
  type: typeof SEARCH_CLEAR_DATA;
}

interface SearchStoreReplaceAttributeAction {
  type: typeof SEARCH_STORE_REPLACE_ATTRIBUTES;
  payload: SpeciesAdopterAttribute;
}

interface SearchStoreAnimalCategoryIdAction {
  type: typeof SEARCH_STORE_ANIMAL_CATEGORY_ID;
  payload: AnimalSpecieCategoryId | Nil;
}

export type SearchActionTypes =
  | SearchStoreNewCoordinateAction
  | SearchStoreNewAttributeAction
  | SearchStoreReplaceAttributeAction
  | SearchStoreParametersAction
  | SearchClearSpeciesDataAction
  | SearchClearDataAction
  | SearchStoreAnimalCategoryIdAction;

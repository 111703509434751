import {
  PREFERENCE_CLEAR,
  PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN,
  PREFERENCE_GUOH_MODAL_SHOWN,
  PREFERENCE_REGISTER_ACTION_LAYER_SHOWN,
  PreferenceActionTypes,
} from './types';

export function preferenceRegisterActionLayerShown(): PreferenceActionTypes {
  return {
    type: PREFERENCE_REGISTER_ACTION_LAYER_SHOWN,
  };
}

export function preferenceFavouriteActionLayerShown(): PreferenceActionTypes {
  return {
    type: PREFERENCE_FAVOURITE_ACTION_LAYER_SHOWN,
  };
}

export function preferenceGUOHModalShown(): PreferenceActionTypes {
  return {
    type: PREFERENCE_GUOH_MODAL_SHOWN,
  };
}

export function preferenceDataClear(): PreferenceActionTypes {
  return {
    type: PREFERENCE_CLEAR,
  };
}

import { ShelterIdType } from '@colensobbdo/shelter-management-frontend-integration';

// state

export interface ShelterState {
  shelterId: ShelterIdType | null;
  name: string | null;
  photoUrl: string | null;
}

// action

export const SHELTER_DATA_UPDATE = 'SHELTER_DATA_UPDATE';
export const SHELTER_DATA_CLEAR = 'SHELTER_DATA_CLEAR';

export type ShelterDataUpdatePayload = Partial<ShelterState>;

interface ShelterDataUpdateAction {
  type: typeof SHELTER_DATA_UPDATE;
  payload: ShelterDataUpdatePayload;
}

interface ShelterDataClearAction {
  type: typeof SHELTER_DATA_CLEAR;
}

export type ShelterActionTypes = ShelterDataUpdateAction | ShelterDataClearAction;
